import { EInvoiceStatus, EProposalStatus } from '~/graphql/types'
import { Container } from './styles'
import { translateInvoiceStatus } from '~/utils'

type InvoiceStatusProps = {
  status: EInvoiceStatus
}
export const InvoiceStatus: React.FC<InvoiceStatusProps> = ({ status }) => {
  return (
    <Container status={status || EProposalStatus.finalized}>
      <span>{translateInvoiceStatus(status || EInvoiceStatus.pending)}</span>
    </Container>
  )
}
