/* eslint-disable no-unused-vars */
import { EBusinessProposalStatus, EInvoiceStatus, EMaritalStatus, EProposalStatus } from '~/graphql/types'

const PROPOSAL_STATUS: { [x in EProposalStatus]: string } = {
  documentation: 'Documentação',
  formalization: 'Formalização',
  finalized: 'Contratado',
  canceled: 'Cancelado'
}

const MODULES_ACTIONS: { [x in string]: string }  = {
  create: 'Criar', 
  updated: 'Alterar',
  read: 'Ver',
  delete: 'Excluir'
}

const FIELDS_FORM: { [x in string]: string } = {
  zipcode: 'CEP',
  street: 'Rua',
  number: 'Número',
  state: 'Estado',
  city: 'Cidade',
  neighborhood: 'Bairro',
  complement: 'Complemento',

  personType: 'Tipo de pessoa',
  name: 'Nome',
  document: 'Documento',
  email: 'E-mail',
  phone: 'Telefone',
  maritalStatus: 'Estado civil',
  nationality: 'Nacionalidade',
  uf: 'Estado',
  dealership: 'Concessionária',
  dealershipId: 'Concessionária',
  dealershipName: 'Concessionária',
  averageEnergyBillValue: 'Valor médio de consumo',
  customerPfDocumentWithPhotoFront: 'Anexar RG ou CNH (Frente)',
  customerPjRepresentativeDocumentWithPhotoFront: 'Anexar RG ou CNH (Frente)',
  customerPfDocumentWithPhotoBack: 'Anexar RG ou CNH (Verso)',
  customerPjRepresentativeDocumentWithPhotoBack: 'Anexar RG ou CNH (Verso)',
  customerPjSocialContract: 'Contrato social',
  customerPjDocumentCard: 'Cartão CNPJ',
  energyBill: 'Ultima fatura de energia',
  identificationNumber: 'Número de identificação'
}

const MARITAL_STATUS: { [x in EMaritalStatus]: string } = {
  divorced: 'Divorciado(a)',
  married: 'Casado(a)',
  single: 'Solteiro(a)',
  widowed: 'Viúvo(a)'
}

const USER_MODULES: { [x in string]: string }  = {
  users: 'Usuários',
  accessControl: 'Perfis',
  dealership: 'Concessionária',
  documentsign: 'Documentos',
  files: 'Arquivos',
  notification: 'Notificações',
  customers: 'Clientes',
  sellers: 'Vendedores',
  proposals: 'Propostas',
  powerGenerator: 'Geradores',
  powerPlants: 'Usinas',
  consortiums: 'Consórcios'
}

export function translateProposalStatus(status: EProposalStatus) {
  return PROPOSAL_STATUS[status] ?? status
}

const BUSINESS_PROPOSAL_STATUS: { [x in EBusinessProposalStatus]: string } = {
  allocated: 'Reservado',
  approved: 'Aprovado',
  finalized: 'Contratado',
  inAnalysis: 'Em análise',
  rejected: 'Recusado',
  canceled: 'Cancelado'
}
export const translateBusinessProposalStatus = (status: EBusinessProposalStatus) => {
  return BUSINESS_PROPOSAL_STATUS[status] ?? status
}

export function translateMaritalStatus(status?: EMaritalStatus) {
  if (!status) return ''
  return MARITAL_STATUS[status] ?? status
}

export function translateFieldsInput(status: string) {
  return FIELDS_FORM[status] ?? status
}

export function modulesActionsTranslate(status: string) {
  return MODULES_ACTIONS[status] ?? status
}

export function userModulesTranslate(status: string) {
  return USER_MODULES[status] ?? status
}

const CUSTOMER_STATUS: { [x in string]: string } = {
  pending: 'Pendente',
  completed: 'Ativo'
}

export const translateCustomerStatus = (status: string) => {
  return CUSTOMER_STATUS[status] ?? status
}

const SPLIT_TYPE: { [x in string]: string } = {
  early: 'Antecipado',
  basic: 'Básico'
}

export const translateSplitType = (status: string) => {
  return SPLIT_TYPE[status] ?? status
}

export const pickMonthNameByNumber = (value: number, type: 'short' | 'long' = 'short'): string => {

  const MONTH_NUMBER: { [x in number]: string } = {
    1: type ==='short' ? 'Jan' : 'Janeiro',
    2: type ==='short' ? 'Fev' : 'Fevereiro',
    3: type ==='short' ? 'Mar' : 'Março',
    4: type ==='short' ? 'Abr' : 'Abril',
    5: type ==='short' ? 'Mai' : 'Maio',
    6: type ==='short' ? 'Jun' : 'Junho',
    7: type ==='short' ? 'Jul' : 'Julho',
    8: type ==='short' ? 'Ago' : 'Agosto',
    9: type ==='short' ? 'Set' : 'Setembro',
    10: type ==='short' ? 'Out' : 'Outubro',
    11: type ==='short' ? 'Nov' : 'Novembro',
    12: type ==='short' ? 'Dez' : 'Dezembro',
  }

  return MONTH_NUMBER[value] ?? value
}

export const INVOICE_STATUS: { [x in EInvoiceStatus]: string } = {
  canceled: 'Cancelado',
  late: 'Atrasado',
  paid: 'Pago',
  pending: 'Pendente',
  requested: 'Requisitado',
  expired: 'Expirado',
  paymentRequest: 'Pagamento requisitado',
  createPayment: 'Pagamento criado',
  generatePDF: 'Fatura gerada'
}

export function translateInvoiceStatus(status: EInvoiceStatus) {
  return INVOICE_STATUS[status] ?? status
}
