import { MouseEventHandler, PropsWithChildren } from 'react'
import { Box } from '@mui/material'
import { useSidebar } from '~/contexts/sidebar'
import { DrawerHeader } from './components/Sidebar/style'
import { Collapsibles, Navbar, Sidebar } from './components'
import { useToggle } from '~/hooks'
import { useContract } from '~/contexts'
import { IconHome, IconLayoutDashboard, IconFileText, IconWallet, IconUser, IconSettings } from '@tabler/icons-react'
import { ChangeToCashback } from '../ChangeToCashback'
import { useLocation } from 'react-router-dom'
import { ChangeToDiscount } from '../ChangeToDiscount'
import { ERewardType } from '~/graphql/types'

export const drawerWidth = 270

export type TSidebarItem = {
  text: string
  icon: JSX.Element
  path?: string
  collapsibleName?: keyof Collapsibles
  nested?: {
    text: string
    icon: JSX.Element
    path: string
    onClick?: MouseEventHandler<HTMLLIElement> | undefined
  }[]
}

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  const { selectedContract, contracts } = useContract()
  const { pathname } = useLocation()
  const { isVisible, toggleSidebar } = useSidebar()
  const { isTrue: discountModalIsVisible, toggle: discountToggle } = useToggle(false)
  const { isTrue: cashbackModalIsVisible, toggle: cashbackToggle } = useToggle(false)

  const hasContractCashback = contracts.some(item => item.reward?.type === ERewardType.cashback)
  const actualContractIsCashback = selectedContract?.reward?.type === ERewardType.cashback
  const hasIuguAccount = Boolean(selectedContract?.singleAccount?.active)
  const shouldShowUnifiedEnergyBill = selectedContract?.singleAccount?.active || (selectedContract?.singleAccount?.history && selectedContract?.singleAccount?.history?.length !== 0)

  const sidebarItems: TSidebarItem[] = [
    contracts.length > 1 && { text: 'Contratos', icon: <IconFileText />, path: '/app/contracts' },
    { text: 'Home', icon: <IconHome />, path: `/app/contracts/${selectedContract?._id}/` },
    { text: 'Dashboard', icon: <IconLayoutDashboard />, path: `/app/contracts/${selectedContract?._id}/dashboard` },
    hasContractCashback && { text: 'Carteira', icon: <IconWallet />, path: `/app/contracts/${selectedContract?._id}/wallet` },
    {
      text: 'Configurações', icon: <IconSettings />, collapsibleName: 'settings', path: `/app/contracts/${selectedContract?._id}/settings`, nested: [
        !actualContractIsCashback && hasIuguAccount && selectedContract?.eligibleForCashback && { icon: <IconUser />, text: 'Alterar para cashback', path: '', onClick: () => cashbackToggle() },
        actualContractIsCashback && { icon: <IconUser />, text: 'Alterar para desconto', path: '', onClick: () => discountToggle() },
        shouldShowUnifiedEnergyBill && hasIuguAccount && { icon: <IconUser />, text: 'Conta única', path: `/app/contracts/${selectedContract?._id}/unified-energy-bill` }
      ].filter(item => Boolean(item))
    }
  ].filter(item => Boolean(item)) as TSidebarItem[]

  return (
    <>
      {pathname === '/app/contracts' ? (
        <>
          {children}
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex' }}>
            <Navbar isDrawerOpen={isVisible} toggleDrawer={toggleSidebar} />
            <Sidebar toggleSidebar={toggleSidebar} sidebarItems={sidebarItems} isDrawerOpen={isVisible} />
            <Box component='main' sx={{ flexGrow: 1, padding: 3, backgroundColor: '#FAFAFA', minHeight: '100vh', height: '100%', width: 'calc(100vw - 294px)' }}>
              <DrawerHeader />
              {children}
            </Box>
          </Box>
          <ChangeToDiscount isVisible={discountModalIsVisible} toggle={discountToggle} />
          <ChangeToCashback isVisible={cashbackModalIsVisible} toggle={cashbackToggle} />
        </>
      )}
    </>
  )
}
