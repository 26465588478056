import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AccessControlCreateDto = {
  active: Scalars['Boolean']['input'];
  modules: Array<ModuleInput>;
  name: Scalars['String']['input'];
};

export type AccessControlDeleteDto = {
  _id: Scalars['String']['input'];
};

export type AccessControlListDto = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AccessControlListSort>;
};

export type AccessControlListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type AccessControlModel = {
  _id: Scalars['ID']['output'];
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  modules: Array<Module>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AccessControlUpdateDto = {
  _id: Scalars['String']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
  modules?: InputMaybe<Array<ModuleInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AccountWalletBalanceModel = {
  balanceCents: Scalars['Float']['output'];
  balanceWithPendingsCents: Scalars['Float']['output'];
  creditValueCentsToday: Scalars['Float']['output'];
  currentDate: Scalars['String']['output'];
  debitValueCentsToday: Scalars['Float']['output'];
};

export type ActionDto = {
  internalName: Scalars['String']['output'];
  publicName: Scalars['String']['output'];
};

export type AddNoteGraphqlDto = {
  proposalId: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type AddressDto = {
  city: Scalars['String']['input'];
  complement?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  neighborhood: Scalars['String']['input'];
  number: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export type AddressGraphqlDto = {
  city: Scalars['String']['input'];
  complement?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  neighborhood: Scalars['String']['input'];
  number: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export type AddressGraphqlModel = {
  city: Scalars['String']['output'];
  complement?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  neighborhood: Scalars['String']['output'];
  number: Scalars['String']['output'];
  state: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zipcode: Scalars['String']['output'];
};

/** User Access control */
export type AffiliatePartnerUserAccessControl = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AffiliatePartnerUserCreateInput = {
  /** username of the user */
  email: Scalars['String']['input'];
  /** name of the user */
  name: Scalars['String']['input'];
  /** phone of the user */
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type AffiliatePartnerUserFilterArgs = {
  inStatus?: InputMaybe<Array<EAffiliatePartnerUserStatus>>;
  nameOrUsernameOrEmail?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
};

export type AffiliatePartnerUserListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type AffiliatePartnerUserLoggedUserOutput = {
  /** Generated accessToken of the user */
  accessToken: Scalars['String']['output'];
};

/** paginate metadata */
export type AffiliatePartnerUserMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** user */
export type AffiliatePartnerUserModel = {
  _id: Scalars['ID']['output'];
  accessControlRef: AffiliatePartnerUserAccessControl;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner: Scalars['Boolean']['output'];
  partnerRef?: Maybe<AffiliatePartnerUserPartnerRef>;
  password?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  status: EAffiliatePartnerUserStatus;
  type?: Maybe<EUserType>;
  updatedAt: Scalars['DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type AffiliatePartnerUserPaginateArgs = {
  filter?: InputMaybe<AffiliatePartnerUserFilterArgs>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<AffiliatePartnerUserListSort>;
};

/** User Access control */
export type AffiliatePartnerUserPartnerRef = {
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type AffiliatePartnerUserRecoverPasswordChangeInput = {
  newPassword: Scalars['String']['input'];
  retypeNewPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type AffiliatePartnerUserRecoverPasswordInput = {
  email: Scalars['String']['input'];
};

/** AffiliatePartnerUserRecoverPassword */
export type AffiliatePartnerUserRecoverPasswordModel = {
  _id: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  partnerUserRef: AffiliatePartnerUserRefModel;
  status: EAffiliatePartnerUserPasswordRecoverStatus;
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AffiliatePartnerUserRefModel = {
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type AffiliatePartnerUserUpdateInput = {
  /** username of the user */
  email: Scalars['String']['input'];
  /** name of the user */
  name: Scalars['String']['input'];
  /** phone of the user */
  phone: Scalars['String']['input'];
  /** status of the user */
  status: EAffiliatePartnerUserStatus;
};

/** users pagination */
export type AffiliatePartnerUsersPaginateModel = {
  data: Array<AffiliatePartnerUserModel>;
  meta: AffiliatePartnerUserMetaModel;
};

export type AnalyseLegalRepresentativeDataDto = {
  document: Scalars['String']['input'];
  pendencies: ProposalLegalRepresentativesPendencies;
  proposalId: Scalars['String']['input'];
};

export type AnalyseRegistrationDataDto = {
  pendencies: ProposalCustomerPendencies;
  proposalId: Scalars['String']['input'];
};

export type AuthAffiliatePartnerUserArgs = {
  /** password of the user */
  password: Scalars['String']['input'];
  /** username of the user */
  username: Scalars['String']['input'];
};

export type AuthCustomerUserArgs = {
  /** password of the user */
  password: Scalars['String']['input'];
  /** username of the user */
  username: Scalars['String']['input'];
};

export type AuthUserArgs = {
  /** password of the user */
  password: Scalars['String']['input'];
  /** username of the user */
  username: Scalars['String']['input'];
};

export type BankGraphqlDto = {
  account: Scalars['String']['input'];
  agency: Scalars['String']['input'];
  code: Scalars['String']['input'];
  type: EBankAccountType;
};

export type BankGraphqlModel = {
  account: Scalars['String']['output'];
  agency: Scalars['String']['output'];
  code: Scalars['String']['output'];
  type: EBankAccountType;
};

export type BankOperationResModel = {
  document: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pix?: Maybe<DigitalAccountPixResModel>;
  type: Scalars['String']['output'];
};

export type BusinessProposalFileModel = {
  fileName?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  storageService?: Maybe<EStorageService>;
};

export type BusinessProposalModel = {
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  businessProposalDespertaNumber?: Maybe<Scalars['Float']['output']>;
  businessProposalPartnerNumber?: Maybe<Scalars['Float']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  canceledBy?: Maybe<Scalars['String']['output']>;
  contractOfAdhesion?: Maybe<BusinessProposalFileModel>;
  createdAt: Scalars['DateTime']['output'];
  file?: Maybe<BusinessProposalFileModel>;
  invoiceValueWithPartner?: Maybe<Scalars['Float']['output']>;
  linkFileToSign: Scalars['String']['output'];
  monthlyEconomyPercent: Scalars['Float']['output'];
  observation?: Maybe<Scalars['String']['output']>;
  powerGeneratorId: Scalars['String']['output'];
  powerPlantId?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  rejectedBy?: Maybe<Scalars['String']['output']>;
  status: EBusinessProposalStatus;
  tariffGreenFlag: Scalars['Float']['output'];
  tariffPurpleFlag: Scalars['Float']['output'];
  tariffRedFlagP1: Scalars['Float']['output'];
  tariffRedFlagP2: Scalars['Float']['output'];
  tariffYellowFlag: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CancelContractDtoGraphql = {
  contractId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
};

export type CancelProposalGraphqlDto = {
  lossReason: ELossReason;
  proposalId: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type CashBackInfoCreateDto = {
  active: Scalars['Boolean']['input'];
};

export type CommissionFilterArgs = {
  begin?: InputMaybe<Scalars['DateTime']['input']>;
  contractId?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  nameCustomerOrSalesman?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
  proposalId?: InputMaybe<Scalars['String']['input']>;
  salesmanId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ECommissionStatusFilter>>;
};

export type CommissionFilterMetricsArgs = {
  begin?: InputMaybe<Scalars['DateTime']['input']>;
  contractId?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  nameCustomerOrSalesman?: InputMaybe<Scalars['String']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
  proposalId?: InputMaybe<Scalars['String']['input']>;
  salesmanId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<ECommissionStatus>>;
};

export type CommissionListMetricsSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type CommissionListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

/** paginate metadata */
export type CommissionMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

export type CommissionMetadataModel = {
  contractId?: Maybe<Scalars['String']['output']>;
  proposalId?: Maybe<Scalars['String']['output']>;
};

export type CommissionMetricsArgs = {
  filter?: InputMaybe<CommissionFilterMetricsArgs>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CommissionListMetricsSort>;
};

export type CommissionMetricsDto = {
  expectedRevenueCents: Scalars['Float']['output'];
  receivableRevenueCents: Scalars['Float']['output'];
};

export type CommissionModel = {
  _id: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  dueDate: Scalars['DateTime']['output'];
  indicationRef: IndicationRefModel;
  metadata: CommissionMetadataModel;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  status: ECommissionStatus;
  updatedAt: Scalars['DateTime']['output'];
  valueCents: Scalars['Float']['output'];
};

export type CommissionPaginateArgs = {
  filter?: InputMaybe<CommissionFilterArgs>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CommissionListSort>;
};

/** Commissions pagination */
export type CommissionPaginateModel = {
  data: Array<CommissionModel>;
  meta: CommissionMetaModel;
};

export type ConsortiumCreateDtoGraphql = {
  address: AddressGraphqlDto;
  bank?: InputMaybe<BankGraphqlDto>;
  cnpj: Scalars['String']['input'];
  email: Scalars['String']['input'];
  fantasyName: Scalars['String']['input'];
  leaderEmail: Scalars['String']['input'];
  leaderName: Scalars['String']['input'];
  legalRepresentative?: InputMaybe<ConsortiumLegalRepresentativeDtoGraphql>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  status: EConsortiumStatus;
};

export type ConsortiumDigitalAccountLegalRepresentativeModel = {
  document?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

/** paginate metadata */
export type ConsortiumDigitalAccountModel = {
  accountOwner?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legalRepresentative?: Maybe<ConsortiumDigitalAccountLegalRepresentativeModel>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type ConsortiumLegalRepresentativeDtoGraphql = {
  document: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/** paginate metadata */
export type ConsortiumLegalRepresentativeModel = {
  document: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ConsortiumListDtoGraphql = {
  nameOrFantasyNameOrDocument?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ConsortiumListSort>;
};

export type ConsortiumListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

/** paginate metadata */
export type ConsortiumMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** consortiums */
export type ConsortiumModel = {
  _id: Scalars['ID']['output'];
  address: AddressGraphqlModel;
  bank?: Maybe<BankGraphqlModel>;
  cnpj: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  digitalAccount?: Maybe<ConsortiumDigitalAccountModel>;
  email: Scalars['String']['output'];
  fantasyName: Scalars['String']['output'];
  leaderEmail: Scalars['String']['output'];
  leaderName: Scalars['String']['output'];
  legalRepresentative?: Maybe<ConsortiumLegalRepresentativeModel>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EConsortiumStatus>;
  updatedAt: Scalars['DateTime']['output'];
};

/** Consortiums pagination */
export type ConsortiumPaginateModel = {
  data: Array<ConsortiumModel>;
  meta: ConsortiumMetaModel;
};

export type ConsortiumUpdateDtoGraphql = {
  _id: Scalars['String']['input'];
  address?: InputMaybe<AddressGraphqlDto>;
  cnpj?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fantasyName?: InputMaybe<Scalars['String']['input']>;
  leaderEmail?: InputMaybe<Scalars['String']['input']>;
  leaderName?: InputMaybe<Scalars['String']['input']>;
  legalRepresentative?: InputMaybe<ConsortiumLegalRepresentativeDtoGraphql>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EConsortiumStatus>;
};

export type ContactPersonCreateDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type ContactPersonSchema = {
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type ContactPersonUpdateDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type ContractAddEnergyBillInfoGraphqlDto = {
  AmountCents?: InputMaybe<Scalars['Float']['input']>;
  _id: Scalars['String']['input'];
  address?: InputMaybe<AddressGraphqlDto>;
  avarageBillAmountCents?: InputMaybe<Scalars['Float']['input']>;
  avarageConsumption?: InputMaybe<Scalars['Float']['input']>;
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  consumerClassification?: InputMaybe<EContractConsumerClassification>;
  dealershipId?: InputMaybe<Scalars['String']['input']>;
  dealershipName?: InputMaybe<Scalars['String']['input']>;
  distributionTaxes?: InputMaybe<Scalars['Float']['input']>;
  energyBillFile?: InputMaybe<ContractFileCreateDto>;
  energyTaxes?: InputMaybe<Scalars['Float']['input']>;
  installationNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceDueDay?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tariffClassification?: InputMaybe<EContractTariffClassification>;
  tension?: InputMaybe<EContractCustomerTension>;
  tusdValue?: InputMaybe<Scalars['Float']['input']>;
};

export type ContractChangeBenefitDto = {
  contractId: Scalars['String']['input'];
  rewardType: ERewardType;
};

export type ContractChangePowerPlantDto = {
  consortiumId: Scalars['String']['input'];
  contractId: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  powerGeneratorId: Scalars['String']['input'];
  powerPlantId: Scalars['String']['input'];
};

export type ContractChangeSingleAccountDto = {
  contractId: Scalars['String']['input'];
  type: ESingleAccountType;
};

export type ContractCreateDtoGraphql = {
  consortiumId?: InputMaybe<Scalars['String']['input']>;
  contractType?: InputMaybe<Array<Scalars['String']['input']>>;
  contractedSharePercent?: InputMaybe<Scalars['Float']['input']>;
  customerId: Scalars['String']['input'];
  dealershipLogin?: InputMaybe<DealershipLoginCreateDto>;
  discountPercent?: InputMaybe<Scalars['Float']['input']>;
  eligibleForCashback?: InputMaybe<Scalars['Boolean']['input']>;
  energyBillInfo?: InputMaybe<ContractEnergyBillInfoGraphqlDto>;
  fidelityMonths?: InputMaybe<Scalars['Float']['input']>;
  generatorId?: InputMaybe<Scalars['String']['input']>;
  gracePeriodMonths?: InputMaybe<Scalars['Float']['input']>;
  indicationRef?: InputMaybe<IndicationRefInputModel>;
  origin?: InputMaybe<EContractOrigin>;
  participationKwh?: InputMaybe<Scalars['Float']['input']>;
  powerPlantId?: InputMaybe<Scalars['String']['input']>;
  proposalId?: InputMaybe<Scalars['String']['input']>;
  reward?: InputMaybe<RewardCreateDto>;
  specialDiscount?: InputMaybe<Array<SpecialDiscountCreateDto>>;
  termOfManagement?: InputMaybe<Array<TermOfManagementCreateGraphqlDto>>;
  type: EContractType;
  validityMonths?: InputMaybe<Scalars['Float']['input']>;
};

export type ContractCustomerRefModel = {
  document: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  personType: EContractPersonType;
  phone?: Maybe<Scalars['String']['output']>;
};

export type ContractEnergyBillInfo = {
  AmountCents?: Maybe<Scalars['Float']['output']>;
  address?: Maybe<AddressGraphqlModel>;
  avarageBillAmountCents?: Maybe<Scalars['Float']['output']>;
  avarageConsumption?: Maybe<Scalars['Float']['output']>;
  clientNumber?: Maybe<Scalars['String']['output']>;
  consumerClassification?: Maybe<EContractConsumerClassification>;
  dealershipId?: Maybe<Scalars['String']['output']>;
  dealershipName?: Maybe<Scalars['String']['output']>;
  distributionTaxes?: Maybe<Scalars['Float']['output']>;
  energyBillFile?: Maybe<ContractFileModel>;
  energyTaxes?: Maybe<Scalars['Float']['output']>;
  installationNumber?: Maybe<Scalars['String']['output']>;
  invoiceDueDay?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tariffClassification?: Maybe<EContractTariffClassification>;
  tension?: Maybe<EContractCustomerTension>;
  tusdValue?: Maybe<Scalars['Float']['output']>;
};

export type ContractEnergyBillInfoGraphqlDto = {
  AmountCents?: InputMaybe<Scalars['Float']['input']>;
  address?: InputMaybe<AddressGraphqlDto>;
  avarageBillAmountCents?: InputMaybe<Scalars['Float']['input']>;
  avarageConsumption?: InputMaybe<Scalars['Float']['input']>;
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  consumerClassification?: InputMaybe<EContractConsumerClassification>;
  dealershipId?: InputMaybe<Scalars['String']['input']>;
  dealershipName?: InputMaybe<Scalars['String']['input']>;
  distributionTaxes?: InputMaybe<Scalars['Float']['input']>;
  energyBillFile?: InputMaybe<ContractFileCreateDto>;
  energyTaxes?: InputMaybe<Scalars['Float']['input']>;
  installationNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceDueDay?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tariffClassification?: InputMaybe<EContractTariffClassification>;
  tension?: InputMaybe<EContractCustomerTension>;
  tusdValue?: InputMaybe<Scalars['Float']['input']>;
};

export type ContractFileCreateDto = {
  fileName: Scalars['String']['input'];
  fileUrl: Scalars['String']['input'];
  key: Scalars['String']['input'];
  storageService: EStorageService;
};

export type ContractFileModel = {
  fileName?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  storageService?: Maybe<EStorageService>;
};

export type ContractInvoiceChangeDueDateDto = {
  allowIncludeFineAndMora?: InputMaybe<Scalars['Boolean']['input']>;
  contractId: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
  newDueDate: Scalars['DateTime']['input'];
};

export type ContractListDtoGraphql = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  dateRange?: InputMaybe<DateRangeGraphqlSchema>;
  inOrigin?: InputMaybe<Array<EContractOrigin>>;
  inPendencies?: InputMaybe<Array<EContractFilterListPendencies>>;
  inStatus?: InputMaybe<Array<EContractStatus>>;
  nameOrDocument?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<ContractListSort>;
  type?: InputMaybe<EContractType>;
};

export type ContractListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

/** paginate metadata */
export type ContractMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** Contracts */
export type ContractModel = {
  _id: Scalars['ID']['output'];
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  activatedBy?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  canceledBy?: Maybe<Scalars['String']['output']>;
  canceledReason?: Maybe<Scalars['String']['output']>;
  consortiumId?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<Array<Scalars['String']['output']>>;
  contractedSharePercent?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  customerId: Scalars['String']['output'];
  customerRef: ContractCustomerRefModel;
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  deactivatedBy?: Maybe<Scalars['String']['output']>;
  dealershipLogin?: Maybe<DealershipLoginModel>;
  eligibleForCashback: Scalars['Boolean']['output'];
  energyBillInfo?: Maybe<ContractEnergyBillInfo>;
  fidelityMonths?: Maybe<Scalars['Float']['output']>;
  gracePeriodMonths?: Maybe<Scalars['Float']['output']>;
  hasRequestedCustomerBankData?: Maybe<Scalars['Boolean']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  inDeactivationAt?: Maybe<Scalars['DateTime']['output']>;
  inDeactivationBy?: Maybe<Scalars['String']['output']>;
  indicationRef?: Maybe<IndicationRefModel>;
  internalNotes?: Maybe<Array<Scalars['String']['output']>>;
  observations?: Maybe<Array<ObservationModel>>;
  origin?: Maybe<EContractOrigin>;
  participationKwh?: Maybe<Scalars['Float']['output']>;
  powerGeneratorId?: Maybe<Scalars['String']['output']>;
  powerPlantId?: Maybe<Scalars['String']['output']>;
  proposalId?: Maybe<Scalars['String']['output']>;
  reward?: Maybe<RewardModel>;
  signDate?: Maybe<Scalars['DateTime']['output']>;
  singleAccount?: Maybe<ContractSingleAccountModel>;
  specialDiscount?: Maybe<Array<SpecialDiscountModel>>;
  status?: Maybe<EContractStatus>;
  termOfAdhesion?: Maybe<Array<ContractTermOfAdhesionModel>>;
  termOfManagement?: Maybe<Array<ContractTermOfManagementModel>>;
  type?: Maybe<EContractType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  validityMonths?: Maybe<Scalars['Float']['output']>;
};

/** Contracts pagination */
export type ContractPaginateModel = {
  data: Array<ContractModel>;
  meta: ContractMetaModel;
};

/** single account */
export type ContractSingleAccountCustomerAreaModel = {
  active: Scalars['Boolean']['output'];
  adhesionAt?: Maybe<Scalars['String']['output']>;
  adhesionBy?: Maybe<EAdhesionBySingleAccount>;
  adhesionResponsibility?: Maybe<Scalars['String']['output']>;
  history: Array<SingleAccountHitoryCustomerAreaModel>;
};

/** single account */
export type ContractSingleAccountModel = {
  active: Scalars['Boolean']['output'];
  adhesionAt?: Maybe<Scalars['DateTime']['output']>;
  adhesionBy?: Maybe<EAdhesionBySingleAccount>;
  adhesionResponsibility?: Maybe<Scalars['String']['output']>;
  history: Array<SingleAccountHitoryModel>;
};

export type ContractSpecialDiscount = {
  discountPercent: Scalars['Float']['input'];
  month: Scalars['Float']['input'];
};

export type ContractTermOfAdhesionModel = {
  active: Scalars['Boolean']['output'];
  canceledAt?: Maybe<Scalars['String']['output']>;
  canceledBy?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  file?: Maybe<ContractFileModel>;
  replaced: Scalars['Boolean']['output'];
  signatureLink?: Maybe<Scalars['String']['output']>;
  signedAt?: Maybe<Scalars['String']['output']>;
  signerEmail: Scalars['String']['output'];
  status: ETermStatus;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type ContractTermOfManagementModel = {
  active: Scalars['Boolean']['output'];
  canceledAt?: Maybe<Scalars['String']['output']>;
  canceledBy?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  documentSignId: Scalars['String']['output'];
  file?: Maybe<ContractFileModel>;
  origin?: Maybe<Scalars['String']['output']>;
  refusedReason?: Maybe<Scalars['String']['output']>;
  replaced: Scalars['Boolean']['output'];
  signatureLink?: Maybe<Scalars['String']['output']>;
  signedAt?: Maybe<Scalars['DateTime']['output']>;
  signerEmail?: Maybe<Scalars['String']['output']>;
  status: ETermStatus;
  type?: Maybe<ETermOfManagementType>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type ContractUpdateCommercialCondition = {
  contractId: Scalars['String']['input'];
  contractedSharePercent?: InputMaybe<Scalars['Float']['input']>;
  defaultDiscount?: InputMaybe<Scalars['Float']['input']>;
  fidelityMonths?: InputMaybe<Scalars['Float']['input']>;
  gracePeriodMonths?: InputMaybe<Scalars['Float']['input']>;
  observation?: InputMaybe<Scalars['String']['input']>;
  participationKwh?: InputMaybe<Scalars['Float']['input']>;
  specialDiscount?: InputMaybe<Array<ContractSpecialDiscount>>;
  validityMonths?: InputMaybe<Scalars['Float']['input']>;
};

export type ContractUpdateDtoGraphql = {
  _id: Scalars['String']['input'];
  consortiumId?: InputMaybe<Scalars['String']['input']>;
  contractType?: InputMaybe<Array<Scalars['String']['input']>>;
  contractedSharePercent?: InputMaybe<Scalars['Float']['input']>;
  dealershipLogin?: InputMaybe<DealershipLoginUpdateDto>;
  discountPercent?: InputMaybe<Scalars['Float']['input']>;
  eligibleForCashback?: InputMaybe<Scalars['Boolean']['input']>;
  fidelityMonths?: InputMaybe<Scalars['Float']['input']>;
  gracePeriodMonths?: InputMaybe<Scalars['Float']['input']>;
  internalNotes?: InputMaybe<Array<Scalars['String']['input']>>;
  participationKwh?: InputMaybe<Scalars['Float']['input']>;
  powerGeneratorId?: InputMaybe<Scalars['String']['input']>;
  powerPlantId?: InputMaybe<Scalars['String']['input']>;
  proposalId?: InputMaybe<Scalars['String']['input']>;
  reward?: InputMaybe<RewardUpdateDto>;
  specialDiscount?: InputMaybe<Array<SpecialDiscountUpdateDto>>;
  status?: InputMaybe<EContractStatus>;
  type?: InputMaybe<EContractType>;
  validityMonths?: InputMaybe<Scalars['Float']['input']>;
};

export type ContractUpdateDealershipLogin = {
  extraPassword: Scalars['String']['input'];
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type ContractUpdateEnergyBillInfo = {
  AmountCents?: InputMaybe<Scalars['Float']['input']>;
  address?: InputMaybe<AddressGraphqlDto>;
  avarageBillAmountCents?: InputMaybe<Scalars['Float']['input']>;
  avarageConsumption?: InputMaybe<Scalars['Float']['input']>;
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  consumerClassification?: InputMaybe<EContractConsumerClassification>;
  contractId: Scalars['String']['input'];
  dealershipId?: InputMaybe<Scalars['String']['input']>;
  dealershipLogin?: InputMaybe<ContractUpdateDealershipLogin>;
  dealershipName?: InputMaybe<Scalars['String']['input']>;
  distributionTaxes?: InputMaybe<Scalars['Float']['input']>;
  energyBillFile?: InputMaybe<FileUploadInputTypeDto>;
  energyTaxes?: InputMaybe<Scalars['Float']['input']>;
  installationNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceDueDay?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  observation?: InputMaybe<Scalars['String']['input']>;
  tariffClassification?: InputMaybe<EContractTariffClassification>;
  tension?: InputMaybe<EContractCustomerTension>;
  tusdValue?: InputMaybe<Scalars['Float']['input']>;
};

export type ContractUpdateManuallyTermManagement = {
  contractId: Scalars['String']['input'];
  file: FilesUploadInputGraphqlDto;
};

export type ContractUpdateManuallyTermManagementApproveDto = {
  approve: Scalars['Boolean']['input'];
  contractId: Scalars['String']['input'];
  refusedReason?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

export type CreateDiscountDto = {
  code: Scalars['String']['input'];
  /** Informar valor entre 0 e 100 % */
  discountPercentage?: Scalars['Float']['input'];
  type: EDiscountTableType;
};

export type CreateIndicationPartnerDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  averageEnergyBillValueCents: Scalars['Float']['input'];
  commissionPercentage?: InputMaybe<Scalars['Float']['input']>;
  dealerShipName: Scalars['String']['input'];
  dealershipId: Scalars['String']['input'];
  document: Scalars['String']['input'];
  email: Scalars['String']['input'];
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name: Scalars['String']['input'];
  nationality?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  personType: EPersonType;
  phone: Scalars['String']['input'];
  uf: Scalars['String']['input'];
};

export type CreateProposalPdfGraphql = {
  template: Scalars['String']['input'];
  variables: CreateProposalPdfVariablesGraphql;
};

export type CreateProposalPdfVariablesGraphql = {
  propoasalInstallationNumber: Scalars['String']['input'];
  proposalAmountWithDesperta: Scalars['String']['input'];
  proposalAmountWithoutDesperta: Scalars['String']['input'];
  proposalAverageMonthSavingValue: Scalars['String']['input'];
  proposalAverageYearSavingValue: Scalars['String']['input'];
  proposalConsumeAverageKwh: Scalars['String']['input'];
  proposalCustomerDocument: Scalars['String']['input'];
  proposalCustomerLegalRepresentativeName: Scalars['String']['input'];
  proposalCustomerName: Scalars['String']['input'];
  proposalDate: Scalars['String']['input'];
  proposalDiscountMonthPerFlagGreenPercentage: Scalars['String']['input'];
  proposalDiscountMonthPerFlagGreenValue: Scalars['String']['input'];
  proposalDiscountMonthPerFlagPurplePercentage: Scalars['String']['input'];
  proposalDiscountMonthPerFlagPurpleValue: Scalars['String']['input'];
  proposalDiscountMonthPerFlagRedP1Percentage: Scalars['String']['input'];
  proposalDiscountMonthPerFlagRedP1Value: Scalars['String']['input'];
  proposalDiscountMonthPerFlagRedP2Percentage: Scalars['String']['input'];
  proposalDiscountMonthPerFlagRedP2Value: Scalars['String']['input'];
  proposalDiscountMonthPerFlagYellowPercentage: Scalars['String']['input'];
  proposalDiscountMonthPerFlagYellowValue: Scalars['String']['input'];
  proposalDiscountPercentage: Scalars['String']['input'];
  proposalDueDate: Scalars['String']['input'];
  proposalNumber: Scalars['String']['input'];
};

export type CreateSimulationPdfGraphql = {
  template: Scalars['String']['input'];
  variables: CreateSimulationPdfVariablesGraphql;
};

export type CreateSimulationPdfVariablesGraphql = {
  applyDiscount?: InputMaybe<Scalars['Float']['input']>;
  baseURL?: InputMaybe<Scalars['String']['input']>;
  proposalToken?: InputMaybe<Scalars['String']['input']>;
  simulationAmountWithDesperta: Scalars['String']['input'];
  simulationAmountWithoutDesperta: Scalars['String']['input'];
  simulationAverageMonthSavingValue: Scalars['String']['input'];
  simulationAverageYearSavingValue: Scalars['String']['input'];
  simulationCustomerDocument: Scalars['String']['input'];
  simulationCustomerLegalRepresentativeName: Scalars['String']['input'];
  simulationCustomerName: Scalars['String']['input'];
  simulationDate: Scalars['String']['input'];
  simulationDueDate: Scalars['String']['input'];
  simulationNumber: Scalars['String']['input'];
};

export type CustomerAreaRewardHistoryModel = {
  description: Scalars['String']['output'];
  ocurredAt: Scalars['String']['output'];
};

export type CustomerAreaRewardModel = {
  history?: Maybe<Array<CustomerAreaRewardHistoryModel>>;
  percent: Scalars['Float']['output'];
  type: ECustomerAreaRewardType;
};

export type CustomerCashbackInvoicePayDto = {
  invoceIds: Array<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

export type CustomerCreateDigitalAccountGraphqlDto = {
  accountOwner: EPersonType;
  bank: BankGraphqlDto;
  customerId?: InputMaybe<Scalars['String']['input']>;
  legalRepresentativeDocument?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerCreateGraphqlDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  bank?: InputMaybe<BankGraphqlDto>;
  document: Scalars['String']['input'];
  email: Scalars['String']['input'];
  files?: InputMaybe<Array<CustomerFileCreateGraphql>>;
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name: Scalars['String']['input'];
  nationality?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Scalars['String']['input']>;
  personType: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  uf?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerFileCreateGraphql = {
  file: FileUploadInputTypeDto;
  sendAt: Scalars['String']['input'];
  status: ECustomerFileStatus;
  type: ECustomerFileType;
};

export type CustomerFileCreateGraphqlDto = {
  file?: InputMaybe<FileUploadInputTypeDto>;
  sendAt?: InputMaybe<Scalars['DateTime']['input']>;
  status: ECustomerFileStatus;
  type: ECustomerFileType;
};

export type CustomerFileModel = {
  file?: Maybe<FileUploadModel>;
  sendAt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ECustomerFileStatus>;
  type?: Maybe<ECustomerFileType>;
};

export type CustomerIndicatioRefModel = {
  address?: Maybe<AddressGraphqlModel>;
  document: Scalars['String']['output'];
  email: Scalars['String']['output'];
  maritalStatus?: Maybe<EMaritalStatus>;
  name: Scalars['String']['output'];
  nationality?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
};

export type CustomerLegalRepresentativeGraphqlDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  document: Scalars['String']['input'];
  email: Scalars['String']['input'];
  files?: InputMaybe<Array<ProposalFileCreateGraphqlDto>>;
  identificationNumber?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name: Scalars['String']['input'];
  nationality?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type CustomerListGraphqlDto = {
  dateRange?: InputMaybe<DateRangeGraphqlSchema>;
  nameOrDocumentOrEmail?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  personType?: InputMaybe<EPersonType>;
  sort?: InputMaybe<CustomerListSort>;
};

export type CustomerListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type CustomerModel = {
  _id: Scalars['ID']['output'];
  address?: Maybe<AddressGraphqlModel>;
  bank?: Maybe<BankGraphqlModel>;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  digitalAccount?: Maybe<DigitalAccountModel>;
  document: Scalars['String']['output'];
  email: Scalars['String']['output'];
  files?: Maybe<Array<CustomerFileModel>>;
  legalRepresentatives?: Maybe<Array<LegalRepresentativeModel>>;
  maritalStatus?: Maybe<EMaritalStatus>;
  name: Scalars['String']['output'];
  nationality?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  origin: Scalars['String']['output'];
  personType: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  status: ECustomerStatus;
  uf?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CustomerPendencyFields = {
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  analysedBy?: Maybe<Scalars['String']['output']>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  sendedAt?: Maybe<Scalars['DateTime']['output']>;
  solvedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CustomerRefCustomerRefModel = {
  document: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  energyBillFile: CustomerUserFileModel;
  name: Scalars['String']['output'];
  personType: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type CustomerRefModel = {
  address?: Maybe<AddressGraphqlModel>;
  digitalAccountId?: Maybe<Scalars['String']['output']>;
  document: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type CustomerUpdateGraphqlDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  document?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  uf?: InputMaybe<Scalars['String']['input']>;
};

/** CustomerUseCashbackModel */
export type CustomerUseCashbackModel = {
  balanceCashbackAfterInvoicedPaidCents: Scalars['Float']['output'];
  balanceCashbackCents: Scalars['Float']['output'];
  invoiceId: Scalars['String']['output'];
  oldInvoiceValueCents: Scalars['Float']['output'];
};

/** User Access control */
export type CustomerUserAccessControl = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CustomerUserContractEnergyBillInfo = {
  AmountCents?: Maybe<Scalars['Float']['output']>;
  address?: Maybe<AddressGraphqlModel>;
  avarageBillAmountCents?: Maybe<Scalars['Float']['output']>;
  avarageConsumption?: Maybe<Scalars['Float']['output']>;
  clientNumber?: Maybe<Scalars['String']['output']>;
  consumerClassification?: Maybe<ECustomerUserConsumerClassification>;
  dealershipId?: Maybe<Scalars['String']['output']>;
  dealershipName?: Maybe<Scalars['String']['output']>;
  distributionTaxes?: Maybe<Scalars['Float']['output']>;
  energyTaxes?: Maybe<Scalars['Float']['output']>;
  installationNumber?: Maybe<Scalars['String']['output']>;
  invoiceDueDay?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tariffClassification?: Maybe<ECustomerUserTariffClassification>;
  tension?: Maybe<ECustomerUserCustomerTension>;
  tusdValue?: Maybe<Scalars['Float']['output']>;
};

/** paginate metadata */
export type CustomerUserContractMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** CustomerUserContracts */
export type CustomerUserContractModel = {
  _id: Scalars['String']['output'];
  activatedAt?: Maybe<Scalars['String']['output']>;
  activatedBy?: Maybe<Scalars['String']['output']>;
  consortiumId?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<Array<Scalars['String']['output']>>;
  contractedSharePercent?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  customerRef?: Maybe<CustomerRefCustomerRefModel>;
  discountPercent?: Maybe<Scalars['Float']['output']>;
  eligibleForCashback: Scalars['Boolean']['output'];
  energyBillInfo?: Maybe<CustomerUserContractEnergyBillInfo>;
  fidelityMonths?: Maybe<Scalars['Float']['output']>;
  gracePeriodMonths?: Maybe<Scalars['Float']['output']>;
  hasRequestedCustomerBankData?: Maybe<Scalars['Boolean']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  internalNotes?: Maybe<Array<Scalars['String']['output']>>;
  participationKwh?: Maybe<Scalars['Float']['output']>;
  powerGeneratorId?: Maybe<Scalars['String']['output']>;
  powerPlantId?: Maybe<Scalars['String']['output']>;
  proposalId?: Maybe<Scalars['String']['output']>;
  reward?: Maybe<CustomerAreaRewardModel>;
  signDate?: Maybe<Scalars['DateTime']['output']>;
  singleAccount?: Maybe<ContractSingleAccountCustomerAreaModel>;
  status?: Maybe<EContractStatus>;
  type?: Maybe<EContractType>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  validityMonths?: Maybe<Scalars['Float']['output']>;
};

export type CustomerUserCreateInput = {
  /** customer id */
  customerId: Scalars['String']['input'];
  /** username of the user */
  email: Scalars['String']['input'];
  /** name of the user */
  name: Scalars['String']['input'];
  /** password of the user */
  password: Scalars['String']['input'];
  /** phone of the user */
  phone: Scalars['String']['input'];
};

export type CustomerUserFileModel = {
  fileName: Scalars['String']['output'];
  fileUrl?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  storageService: EStorageService;
};

export type CustomerUserFilterArgs = {
  nameOrUsernameOrEmail?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ECustomerUserStatus>;
};

export type CustomerUserListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type CustomerUserLoggedUserOutput = {
  /** Generated accessToken of the user */
  accessToken: Scalars['String']['output'];
};

/** paginate metadata */
export type CustomerUserMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** user */
export type CustomerUserModel = {
  _id: Scalars['ID']['output'];
  accessControlRef: CustomerUserAccessControl;
  createdAt: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  customerRef?: Maybe<UserCustomerRefModel>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  password: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  status: ECustomerUserStatus;
  type?: Maybe<EUserType>;
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};

export type CustomerUserPaginateArgs = {
  filter?: InputMaybe<CustomerUserFilterArgs>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<CustomerUserListSort>;
};

export type CustomerUserRecoverPasswordChangeInput = {
  newPassword: Scalars['String']['input'];
  retypeNewPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CustomerUserRecoverPasswordInput = {
  email: Scalars['String']['input'];
};

/** customerUserRecoverPassword */
export type CustomerUserRecoverPasswordModel = {
  _id: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  status: ECustomerUserPasswordRecoverStatus;
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userRef: CustomerUserRefModel;
};

export type CustomerUserRefModel = {
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

/** users pagination */
export type CustomerUsersPaginateModel = {
  data: Array<CustomerUserModel>;
  meta: CustomerUserMetaModel;
};

/** customerWithdraw */
export type CustomerWithdrawModel = {
  _id: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerRef?: Maybe<UserCustomerRefModel>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  digitalAccountId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  status: ECustomerWithdrawStatus;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type CustomerWithdrawRequestInputDto = {
  description?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  value: Scalars['Float']['input'];
};

export type DateRangeGraphqlSchema = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type DealershipCreateDto = {
  available: Scalars['Boolean']['input'];
  distributorId: Scalars['String']['input'];
  locationUf: Scalars['String']['input'];
  maxDiscountPercentagePf: Scalars['Float']['input'];
  maxDiscountPercentagePj: Scalars['Float']['input'];
  minimumExceptionValue?: InputMaybe<DealershipMinimumExceptionValueCreateDto>;
  name: Scalars['String']['input'];
  partnerBenefitToCustomer: DealershipPartnerBenefitToCustomerCreateDto;
  resolutionAneel?: InputMaybe<DealershipResolutionAneelCreateDto>;
  resolutionClasses?: InputMaybe<Array<DealershipResolutionClassDto>>;
};

export type DealershipDeleteDto = {
  _id: Scalars['String']['input'];
};

/** paginate metadata */
export type DealershipFlagTaxMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

export type DealershipListDto = {
  available?: InputMaybe<Scalars['Boolean']['input']>;
  hasDiscountPercentages?: InputMaybe<Scalars['Boolean']['input']>;
  locationUf?: InputMaybe<EBrazilStates>;
  maxDiscountPercentagePf?: InputMaybe<Scalars['Float']['input']>;
  maxDiscountPercentagePj?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DealershipListSort>;
};

export type DealershipListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type DealershipLoginCreateDto = {
  extraPassword: Scalars['String']['input'];
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type DealershipLoginModel = {
  extraPassword?: Maybe<Scalars['String']['output']>;
  login: Scalars['String']['output'];
  password: Scalars['String']['output'];
};

export type DealershipLoginUpdateDto = {
  extraPassword?: InputMaybe<Scalars['String']['input']>;
  login: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** paginate metadata */
export type DealershipMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

export type DealershipMinimumExceptionValueCreateDto = {
  allowException: Scalars['Boolean']['input'];
  value: Scalars['Float']['input'];
};

/** Minimum exception value  */
export type DealershipMinimumExceptionValueModel = {
  allowException: Scalars['Boolean']['output'];
  value: Scalars['Float']['output'];
};

/** dealerships */
export type DealershipModel = {
  _id: Scalars['ID']['output'];
  available: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  distributorId?: Maybe<Scalars['String']['output']>;
  locationUf?: Maybe<Scalars['String']['output']>;
  maxDiscountPercentagePf?: Maybe<Scalars['Float']['output']>;
  maxDiscountPercentagePj?: Maybe<Scalars['Float']['output']>;
  minimumExceptionValue?: Maybe<DealershipMinimumExceptionValueModel>;
  name?: Maybe<Scalars['String']['output']>;
  partnerBenefitToCustomer: DealershipPartnerBenefitToCustomerModel;
  resolutionAneel?: Maybe<DealershipResolutionAneelModel>;
  resolutionClasses: Array<DealershipResolutionClasseModel>;
  updatedAt: Scalars['DateTime']['output'];
};

/** dealerships pagination */
export type DealershipPaginateModel = {
  data: Array<DealershipModel>;
  meta: DealershipMetaModel;
};

export type DealershipPartnerBenefitToCustomerCreateDto = {
  customerPFDiscountMax: Scalars['Float']['input'];
  customerPFDiscountMin: Scalars['Float']['input'];
  customerPFDiscountSuggested: Scalars['Float']['input'];
  customerPJDiscountMax: Scalars['Float']['input'];
  customerPJDiscountMin: Scalars['Float']['input'];
  customerPJDiscountSuggested: Scalars['Float']['input'];
};

/** Partner benefit to customer */
export type DealershipPartnerBenefitToCustomerModel = {
  customerPFDiscountMax?: Maybe<Scalars['Float']['output']>;
  customerPFDiscountMin?: Maybe<Scalars['Float']['output']>;
  customerPFDiscountSuggested?: Maybe<Scalars['Float']['output']>;
  customerPJDiscountMax?: Maybe<Scalars['Float']['output']>;
  customerPJDiscountMin?: Maybe<Scalars['Float']['output']>;
  customerPJDiscountSuggested?: Maybe<Scalars['Float']['output']>;
};

export type DealershipResolutionAneelCreateDto = {
  linkUrl: Scalars['String']['input'];
  modality?: InputMaybe<EDealershipResolutionModality>;
  resolutionHomologed: Scalars['String']['input'];
  validity: Scalars['String']['input'];
};

/** Resoluções da Aneel em vigor */
export type DealershipResolutionAneelModel = {
  linkUrl: Scalars['String']['output'];
  modality?: Maybe<EDealershipResolutionModality>;
  resolutionHomologed: Scalars['String']['output'];
  validity: Scalars['String']['output'];
};

export type DealershipResolutionClassDto = {
  class: EDealershipResolutuionClass;
  cofinsPercentage: Scalars['Float']['input'];
  fcp?: InputMaybe<Scalars['Float']['input']>;
  icmsPercentage: Scalars['Float']['input'];
  pisPercentage: Scalars['Float']['input'];
  teValueCents: Scalars['Float']['input'];
  tusdValueCents: Scalars['Float']['input'];
  wireBCalculationBaseValueCents?: InputMaybe<Scalars['Float']['input']>;
};

export type DealershipResolutionClasseModel = {
  class: EDealershipResolutuionClass;
  cofinsPercentage: Scalars['Float']['output'];
  fcp?: Maybe<Scalars['Float']['output']>;
  icmsPercentage: Scalars['Float']['output'];
  pisPercentage: Scalars['Float']['output'];
  teValueCents: Scalars['Float']['output'];
  tusdValueCents: Scalars['Float']['output'];
  wireBCalculationBaseValueCents?: Maybe<Scalars['Float']['output']>;
};

export type DealershipUpdateDto = {
  _id: Scalars['String']['input'];
  available?: InputMaybe<Scalars['Boolean']['input']>;
  distributorId?: InputMaybe<Scalars['String']['input']>;
  locationUf?: InputMaybe<Scalars['String']['input']>;
  maxDiscountPercentagePf?: InputMaybe<Scalars['Float']['input']>;
  maxDiscountPercentagePj?: InputMaybe<Scalars['Float']['input']>;
  minimumExceptionValue?: InputMaybe<DealershipMinimumExceptionValueCreateDto>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerBenefitToCustomer?: InputMaybe<DealershipPartnerBenefitToCustomerCreateDto>;
  resolutionAneel?: InputMaybe<DealershipResolutionAneelCreateDto>;
  resolutionClasses?: InputMaybe<Array<DealershipResolutionClassDto>>;
};

export type DigitalAccountBalanceModel = {
  balanceCents?: Maybe<Scalars['Float']['output']>;
};

export type DigitalAccountInvoiceSplitModel = {
  amountCents: Scalars['Float']['output'];
  barcode?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  internalAccountIdToPayment?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type DigitalAccountLegalRepresentativeModel = {
  document?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type DigitalAccountModel = {
  accountOwner?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legalRepresentative?: Maybe<Array<DigitalAccountLegalRepresentativeModel>>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
};

export type DigitalAccountPixResModel = {
  key: Scalars['String']['output'];
  keyType: Scalars['String']['output'];
};

export type DigitalAccountWalletExtractResponseModel = {
  _id: Scalars['String']['output'];
  balanceCents: Scalars['Float']['output'];
  bankOperation?: Maybe<BankOperationResModel>;
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  idempotencyKey: Scalars['String']['output'];
  metadata?: Maybe<Metadata>;
  operationType: Scalars['String']['output'];
  status: Scalars['String']['output'];
  trackOperation?: Maybe<TrackOperationResModel>;
  updatedAt: Scalars['String']['output'];
  valueCents: Scalars['Float']['output'];
};

export type DiscountExceptionSchema = {
  customerPFDiscountMax: Scalars['Float']['output'];
  customerPFDiscountMin: Scalars['Float']['output'];
  customerPFDiscountSuggested: Scalars['Float']['output'];
  customerPJDiscountMax: Scalars['Float']['output'];
  customerPJDiscountMin: Scalars['Float']['output'];
  customerPJDiscountSuggested: Scalars['Float']['output'];
  dealershipId: Scalars['String']['output'];
  dealershipName: Scalars['String']['output'];
};

export type DiscountOfferedSchema = {
  customerPFDiscountOffered: Scalars['Float']['output'];
  customerPJDiscountOffered: Scalars['Float']['output'];
  dealershipId: Scalars['String']['output'];
  dealershipName: Scalars['String']['output'];
};

export type DiscountTableDto = {
  _id: Scalars['ID']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  discountPercentage: Scalars['Float']['output'];
  type: EDiscountTableType;
  updatedAt: Scalars['DateTime']['output'];
};

export type DiscountTableListDto = {
  filter?: InputMaybe<FilterArgs>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DiscountTableListSort>;
};

export type DiscountTableListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type DiscountTableModel = {
  _id: Scalars['ID']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  discountPercentage: Scalars['Float']['output'];
  type: EDiscountTableType;
  updatedAt: Scalars['DateTime']['output'];
};

export type DiscountTablePaginateDto = {
  data: Array<DiscountTableDto>;
  meta: Meta;
};

export type DistributorListDto = {
  active?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DistributorListSort>;
};

export type DistributorListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

/** paginate metadata */
export type DistributorMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** distributors */
export type DistributorModel = {
  _id: Scalars['ID']['output'];
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  distributorId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** distributors pagination */
export type DistributorPaginateModel = {
  data: Array<DistributorModel>;
  meta: DistributorMetaModel;
};

export type DocumentSignerModel = {
  email: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  requestSignId?: Maybe<Scalars['String']['output']>;
};

export enum EAdhesionBySingleAccount {
  customer = 'customer',
  desperta = 'desperta'
}

export enum EAffiliatePartnerUserPasswordRecoverStatus {
  completed = 'completed',
  expired = 'expired',
  pending = 'pending'
}

export enum EAffiliatePartnerUserStatus {
  active = 'active',
  inactive = 'inactive',
  pending = 'pending'
}

export enum EBankAccountType {
  cc = 'cc',
  cp = 'cp'
}

export enum EBrazilStates {
  AC = 'AC',
  AL = 'AL',
  AM = 'AM',
  AP = 'AP',
  BA = 'BA',
  CE = 'CE',
  DF = 'DF',
  ES = 'ES',
  GO = 'GO',
  MA = 'MA',
  MG = 'MG',
  MS = 'MS',
  MT = 'MT',
  PA = 'PA',
  PB = 'PB',
  PE = 'PE',
  PI = 'PI',
  PR = 'PR',
  RJ = 'RJ',
  RN = 'RN',
  RO = 'RO',
  RR = 'RR',
  RS = 'RS',
  SC = 'SC',
  SE = 'SE',
  SP = 'SP'
}

export enum EBusinessProposalStatus {
  allocated = 'allocated',
  approved = 'approved',
  canceled = 'canceled',
  finalized = 'finalized',
  inAnalysis = 'inAnalysis',
  rejected = 'rejected'
}

export enum EChangedBy {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export enum ECommissionStatus {
  canceled = 'canceled',
  extension = 'extension',
  late = 'late',
  notPaid = 'notPaid',
  paid = 'paid'
}

export enum ECommissionStatusFilter {
  extension = 'extension',
  late = 'late',
  notPaid = 'notPaid',
  paid = 'paid'
}

export enum EConsortiumStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum EConsumerClassification {
  comercial = 'comercial',
  industrial = 'industrial',
  outros = 'outros',
  residencial = 'residencial',
  rural = 'rural'
}

export enum EContractConsumerClassification {
  comercial = 'comercial',
  industrial = 'industrial',
  outros = 'outros',
  residencial = 'residencial',
  rural = 'rural'
}

export enum EContractCustomerTension {
  bifasico = 'bifasico',
  monofasico = 'monofasico',
  trifasico = 'trifasico'
}

export enum EContractFilterListPendencies {
  signTermAdhesion = 'signTermAdhesion',
  signTermManagement = 'signTermManagement'
}

export enum EContractOrigin {
  activeCampaign = 'activeCampaign',
  internal = 'internal',
  partner = 'partner',
  partnerApi = 'partnerApi',
  partnerLink = 'partnerLink',
  rdStation = 'rdStation',
  website = 'website'
}

export enum EContractPersonType {
  pf = 'pf',
  pj = 'pj'
}

export enum EContractStatus {
  active = 'active',
  cancellationRequested = 'cancellationRequested',
  deactivated = 'deactivated',
  inDeactivation = 'inDeactivation',
  readyToSend = 'readyToSend',
  reserved = 'reserved',
  sent = 'sent',
  signatures = 'signatures'
}

export enum EContractTariffClassification {
  A4 = 'A4',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3'
}

export enum EContractType {
  desperta = 'desperta',
  externalGenerator = 'externalGenerator'
}

export enum ECustomerAreaRewardType {
  cashback = 'cashback',
  discount = 'discount'
}

export enum ECustomerFileStatus {
  accepted = 'accepted',
  pending = 'pending',
  rejected = 'rejected',
  send = 'send'
}

export enum ECustomerFileType {
  customerPfDocumentWithPhotoBack = 'customerPfDocumentWithPhotoBack',
  customerPfDocumentWithPhotoFront = 'customerPfDocumentWithPhotoFront',
  customerPjDocumentCard = 'customerPjDocumentCard',
  customerPjRepresentativeDocumentWithPhotoBack = 'customerPjRepresentativeDocumentWithPhotoBack',
  customerPjRepresentativeDocumentWithPhotoFront = 'customerPjRepresentativeDocumentWithPhotoFront',
  customerPjSocialContract = 'customerPjSocialContract',
  energyBill = 'energyBill'
}

export enum ECustomerPersonType {
  pf = 'pf',
  pj = 'pj'
}

export enum ECustomerStatus {
  completed = 'completed',
  pending = 'pending'
}

export enum ECustomerTension {
  bifasico = 'bifasico',
  monofasico = 'monofasico',
  trifasico = 'trifasico'
}

export enum ECustomerUserConsumerClassification {
  comercial = 'comercial',
  industrial = 'industrial',
  outros = 'outros',
  residencial = 'residencial',
  rural = 'rural'
}

export enum ECustomerUserCustomerTension {
  bifasico = 'bifasico',
  monofasico = 'monofasico',
  trifasico = 'trifasico'
}

export enum ECustomerUserPasswordRecoverStatus {
  completed = 'completed',
  expired = 'expired',
  pending = 'pending'
}

export enum ECustomerUserStatus {
  active = 'active',
  blocked = 'blocked',
  inactive = 'inactive',
  pending = 'pending'
}

export enum ECustomerUserTariffClassification {
  A4 = 'A4',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3'
}

export enum ECustomerWithdrawStatus {
  error = 'error',
  finished = 'finished',
  pending = 'pending',
  requested = 'requested'
}

export enum EDealershipResolutionModality {
  conventional = 'conventional',
  notConventional = 'notConventional'
}

export enum EDealershipResolutuionClass {
  b1 = 'b1',
  b2 = 'b2',
  b3 = 'b3'
}

export enum EDiscountTableType {
  customerFloatFlag = 'customerFloatFlag',
  customerNotFloatFlag = 'customerNotFloatFlag'
}

export enum EDocumentSignStatus {
  created = 'created',
  expired = 'expired',
  pending = 'pending',
  refused = 'refused',
  signatureRequested = 'signatureRequested',
  signed = 'signed',
  uploaded = 'uploaded'
}

export enum EIndicationOrigin {
  api = 'api',
  link = 'link',
  partnerArea = 'partnerArea'
}

export enum EIndicationStatus {
  activeContract = 'activeContract',
  allocateDealership = 'allocateDealership',
  canceled = 'canceled',
  canceledContract = 'canceledContract',
  canceledProposal = 'canceledProposal',
  expiredProposal = 'expiredProposal',
  pending = 'pending',
  requestedProposal = 'requestedProposal',
  signedContract = 'signedContract'
}

export enum EIndicationType {
  customer = 'customer',
  partner = 'partner',
  partnerApi = 'partnerApi',
  partnerLink = 'partnerLink'
}

export enum EInvoiceBenefitType {
  cashback = 'cashback',
  discount = 'discount'
}

export enum EInvoiceStatus {
  canceled = 'canceled',
  createPayment = 'createPayment',
  expired = 'expired',
  generatePDF = 'generatePDF',
  late = 'late',
  paid = 'paid',
  paymentRequest = 'paymentRequest',
  pending = 'pending',
  requested = 'requested'
}

export enum EInvoiceTemplate {
  gd1WithCashback = 'gd1WithCashback',
  gd1WithCashbackUnified = 'gd1WithCashbackUnified',
  gd1WithDiscount = 'gd1WithDiscount',
  gd1WithDiscountUnified = 'gd1WithDiscountUnified'
}

export enum ELossReason {
  areaNotCovered = 'areaNotCovered',
  beneficiaryDueToLowIncome = 'beneficiaryDueToLowIncome',
  duplicateLead = 'duplicateLead',
  insufficientInvoiceAmount = 'insufficientInvoiceAmount',
  leadCreatedForTesting = 'leadCreatedForTesting',
  lowAverageEnergyBillAmount = 'lowAverageEnergyBillAmount',
  noContactReturned = 'noContactReturned',
  noInterestLostInterest = 'noInterestLostInterest',
  other = 'other',
  weDoNotServicePfInTheRegion = 'weDoNotServicePfInTheRegion',
  weDoNotServiceTheRegionPjOrPf = 'weDoNotServiceTheRegionPjOrPf'
}

export enum EMaritalStatus {
  divorced = 'divorced',
  married = 'married',
  single = 'single',
  widowed = 'widowed'
}

export enum EModule {
  accessControl = 'accessControl',
  affiliatePartnerArea = 'affiliatePartnerArea',
  consortiums = 'consortiums',
  contractNotes = 'contractNotes',
  contracts = 'contracts',
  customers = 'customers',
  customersArea = 'customersArea',
  dealership = 'dealership',
  documentsign = 'documentsign',
  files = 'files',
  invoices = 'invoices',
  notification = 'notification',
  partner = 'partner',
  powerGenerator = 'powerGenerator',
  powerPlants = 'powerPlants',
  proposals = 'proposals',
  sellers = 'sellers',
  users = 'users'
}

export enum EPartnerStatus {
  active = 'active',
  inactive = 'inactive'
}

export enum EPasswordRecoverStatus {
  completed = 'completed',
  expired = 'expired',
  pending = 'pending'
}

export enum EPersonType {
  pf = 'pf',
  pj = 'pj'
}

export enum EPowerPlantModality {
  gd1 = 'gd1',
  gd2 = 'gd2',
  gd3 = 'gd3'
}

export enum EProposalCustomerPendencies {
  averageEnergyBillValue = 'averageEnergyBillValue',
  city = 'city',
  complement = 'complement',
  country = 'country',
  dealershipId = 'dealershipId',
  dealershipName = 'dealershipName',
  document = 'document',
  email = 'email',
  maritalStatus = 'maritalStatus',
  name = 'name',
  nationality = 'nationality',
  neighborhood = 'neighborhood',
  nickname = 'nickname',
  number = 'number',
  personType = 'personType',
  phone = 'phone',
  state = 'state',
  street = 'street',
  uf = 'uf',
  zipcode = 'zipcode'
}

export enum EProposalFileStatus {
  accepted = 'accepted',
  pending = 'pending',
  rejected = 'rejected',
  send = 'send'
}

export enum EProposalFileType {
  customerPfDocumentWithPhotoBack = 'customerPfDocumentWithPhotoBack',
  customerPfDocumentWithPhotoFront = 'customerPfDocumentWithPhotoFront',
  customerPjDocumentCard = 'customerPjDocumentCard',
  customerPjRepresentativeDocumentWithPhotoBack = 'customerPjRepresentativeDocumentWithPhotoBack',
  customerPjRepresentativeDocumentWithPhotoFront = 'customerPjRepresentativeDocumentWithPhotoFront',
  customerPjSocialContract = 'customerPjSocialContract',
  energyBill = 'energyBill'
}

export enum EProposalFilterListPendencies {
  addressData = 'addressData',
  documentation = 'documentation',
  energyBill = 'energyBill',
  personalData = 'personalData',
  signTermAdhesion = 'signTermAdhesion'
}

export enum EProposalLegalRepresentantPendencies {
  address = 'address',
  document = 'document',
  email = 'email',
  identificationNumber = 'identificationNumber',
  maritalStatus = 'maritalStatus',
  name = 'name',
  nationality = 'nationality',
  phone = 'phone'
}

export enum EProposalOrigin {
  activeCampaign = 'activeCampaign',
  internal = 'internal',
  partner = 'partner',
  partnerApi = 'partnerApi',
  partnerLink = 'partnerLink',
  rdStation = 'rdStation',
  website = 'website'
}

export enum EProposalPendenciesStatus {
  fullyResolved = 'fullyResolved',
  partiallyResolved = 'partiallyResolved'
}

export enum EProposalStatus {
  canceled = 'canceled',
  documentation = 'documentation',
  finalized = 'finalized',
  formalization = 'formalization'
}

export enum EProviderInvoice {
  api = 'api',
  gera = 'gera'
}

export enum EProviderInvoiceStatus {
  canceled = 'canceled',
  generated = 'generated',
  imported = 'imported',
  importedDealership = 'importedDealership',
  invoiced = 'invoiced'
}

export enum EProviderInvoiceType {
  dealership = 'dealership',
  powerPlan = 'powerPlan'
}

export enum ERewardType {
  cashback = 'cashback',
  discount = 'discount'
}

export enum ESingleAccountType {
  adhesion = 'adhesion',
  cancel = 'cancel'
}

export enum EStage {
  awaitingSignature = 'awaitingSignature',
  contactReestablished = 'contactReestablished',
  firstContactAttempt = 'firstContactAttempt',
  lead = 'lead',
  legalAssembly = 'legalAssembly',
  lost = 'lost',
  negotiating = 'negotiating',
  negotiationCoolingDown = 'negotiationCoolingDown',
  opportunity = 'opportunity',
  proposalContracted = 'proposalContracted',
  secondContactAttempt = 'secondContactAttempt',
  thirdContactAttempt = 'thirdContactAttempt'
}

export enum EStorageService {
  awsS3 = 'awsS3'
}

export enum ETariffClassification {
  A4 = 'A4',
  B1 = 'B1',
  B2 = 'B2',
  B3 = 'B3'
}

export enum ETermOfManagementType {
  automatic = 'automatic',
  manual = 'manual'
}

export enum ETermStatus {
  canceled = 'canceled',
  created = 'created',
  expired = 'expired',
  refused = 'refused',
  replaced = 'replaced',
  requestedSignature = 'requestedSignature',
  signed = 'signed',
  uploaded = 'uploaded'
}

export enum EUserStatus {
  active = 'active',
  blocked = 'blocked',
  inactive = 'inactive',
  pending = 'pending'
}

export enum EUserType {
  customer = 'customer',
  internal = 'internal',
  partner = 'partner',
  seller = 'seller'
}

export enum EWalletExtractStatus {
  error = 'error',
  finalized = 'finalized',
  processing = 'processing',
  requested = 'requested'
}

export type EnergyBill = {
  dealershipBarCode?: Maybe<Scalars['String']['output']>;
  dealershipBarCodeDueDate?: Maybe<Scalars['DateTime']['output']>;
  dealershipBarCodeValueCents?: Maybe<Scalars['Float']['output']>;
  dealershipInvoiceValueCents: Scalars['Float']['output'];
  despertaInvoiceValueCents: Scalars['Float']['output'];
  energyGeneratedInMonth: Scalars['Float']['output'];
  energyGenerationBalance: Scalars['Float']['output'];
  energyUsedMonth: Scalars['Float']['output'];
  lastMonthGenerationBalance: Scalars['Float']['output'];
  reference: ReferenceOrderModel;
};

export type FileFilterArgs = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

/** paginate metadata */
export type FileMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** metadata */
export type FileMetadataModel = {
  sample: Scalars['String']['output'];
};

/** file */
export type FileModel = {
  _id: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Scalars['DateTime']['output'];
  fileName: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  key: Scalars['String']['output'];
  metadata?: Maybe<FileMetadataModel>;
  mimetype: Scalars['String']['output'];
  storageService?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FilePaginateArgs = {
  filter?: InputMaybe<FileFilterArgs>;
};

export type FileUploadInputTypeDto = {
  fileName: Scalars['String']['input'];
  fileUrl: Scalars['String']['input'];
  key: Scalars['String']['input'];
  storageService: Scalars['String']['input'];
};

export type FileUploadModel = {
  fileName: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  key: Scalars['String']['output'];
  storageService: Scalars['String']['output'];
};

/** files pagination */
export type FilesPaginateModel = {
  data: Array<FileModel>;
  meta: FileMetaModel;
};

export type FilesUploadInputGraphqlDto = {
  fileName: Scalars['String']['input'];
  fileUrl: Scalars['String']['input'];
  key: Scalars['String']['input'];
  storageService: EStorageService;
};

export type FilterArgs = {
  begin?: InputMaybe<Scalars['DateTime']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  floatType?: InputMaybe<Array<EDiscountTableType>>;
};

export type FilterDigitalAccountWalletExtractGraphqlDto = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  dateBegin: Scalars['DateTime']['input'];
  dateEnd: Scalars['DateTime']['input'];
  pageNumber?: InputMaybe<Scalars['Float']['input']>;
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<EWalletExtractStatus>;
};

export type GeoLocationResModel = {
  accuracy: Scalars['Float']['output'];
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
};

export type GetMyContractsGraphql = {
  inStatus?: InputMaybe<Array<EContractStatus>>;
};

export type History = {
  action?: Maybe<Scalars['String']['output']>;
  createBy: HistoryCreateBy;
  ocurredAt: Scalars['DateTime']['output'];
  status?: Maybe<EInvoiceStatus>;
};

export type HistoryCreateBy = {
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type IndicationConditionInputModel = {
  additionalComissionPercentage: Scalars['Float']['input'];
  baseCommissionPercentage: Scalars['Float']['input'];
  commissionAmountPercentage: Scalars['Float']['input'];
  commissionInvoices: Scalars['Float']['input'];
  customerDiscountMax: Scalars['Float']['input'];
  customerDiscountMin: Scalars['Float']['input'];
  customerDiscountOffered: Scalars['Float']['input'];
  customerDiscountSuggested: Scalars['Float']['input'];
  dealershipId: Scalars['String']['input'];
  dealershipName: Scalars['String']['input'];
  uf: Scalars['String']['input'];
};

export type IndicationConditionModel = {
  additionalComissionPercentage: Scalars['Float']['output'];
  baseCommissionPercentage: Scalars['Float']['output'];
  commissionAmountPercentage: Scalars['Float']['output'];
  commissionInvoices: Scalars['Float']['output'];
  customerDiscountMax: Scalars['Float']['output'];
  customerDiscountMin: Scalars['Float']['output'];
  customerDiscountOffered: Scalars['Float']['output'];
  customerDiscountSuggested: Scalars['Float']['output'];
  dealershipId: Scalars['String']['output'];
  dealershipName: Scalars['String']['output'];
  uf: Scalars['String']['output'];
};

export type IndicationFilterArgs = {
  begin?: InputMaybe<Scalars['DateTime']['input']>;
  contractId?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  nameCustomerOrSalesman?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Array<EIndicationOrigin>>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
  proposalId?: InputMaybe<Scalars['String']['input']>;
  salesmanId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<EIndicationStatus>>;
};

export type IndicationListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

/** paginate metadata */
export type IndicationMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

export type IndicationMetadataModel = {
  contractId?: Maybe<Scalars['String']['output']>;
  partnerRef?: Maybe<PartnerRefModel>;
  preProposalFileKey?: Maybe<Scalars['String']['output']>;
  proposalId?: Maybe<Scalars['String']['output']>;
};

export type IndicationMetricsDto = {
  activeContract: Scalars['Float']['output'];
  allocateDealership: Scalars['Float']['output'];
  canceled: Scalars['Float']['output'];
  canceledContract: Scalars['Float']['output'];
  canceledProposal: Scalars['Float']['output'];
  expiredProposal: Scalars['Float']['output'];
  indicationCount: Scalars['Float']['output'];
  pending: Scalars['Float']['output'];
  requestedProposal: Scalars['Float']['output'];
  signedContract: Scalars['Float']['output'];
};

export type IndicationModel = {
  _id: Scalars['ID']['output'];
  averageEnergyBillValueCents: Scalars['Float']['output'];
  condition?: Maybe<IndicationConditionModel>;
  createdAt: Scalars['DateTime']['output'];
  customerRef: CustomerIndicatioRefModel;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  metadata: IndicationMetadataModel;
  origin: EIndicationOrigin;
  salesmanRef: SalesmanRefModel;
  status: EIndicationStatus;
  type: EIndicationType;
  updatedAt: Scalars['DateTime']['output'];
};

export type IndicationPaginateArgs = {
  filter?: InputMaybe<IndicationFilterArgs>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<IndicationListSort>;
};

/** indications pagination */
export type IndicationPaginateModel = {
  data: Array<IndicationModel>;
  meta: IndicationMetaModel;
};

export type IndicationProposalFileDto = {
  file?: InputMaybe<FilesUploadInputGraphqlDto>;
  status: EProposalFileStatus;
  type: EProposalFileType;
};

export type IndicationRefInputModel = {
  condition?: InputMaybe<IndicationConditionInputModel>;
  expired?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  partner: PartnerRefInputModel;
  salesman: SalesmanRefInputModel;
  type: EIndicationType;
};

export type IndicationRefModel = {
  condition?: Maybe<IndicationConditionModel>;
  expired?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  partner?: Maybe<PartnerRefModel>;
  salesman: SalesmanRefModel;
  type: EIndicationType;
};

/** invoice calculate new duedate */
export type InvoiceCalculateNewDueDate = {
  invoiceId: Scalars['String']['output'];
  newDueDateCalculated: Array<InvoiceCalculateNewDueDateItem>;
};

export type InvoiceCalculateNewDueDateItem = {
  amountCents: Scalars['Float']['output'];
  dueDate: Scalars['DateTime']['output'];
  fineAmountCents: Scalars['Float']['output'];
  interestAmountCents: Scalars['Float']['output'];
  invoiceValue: Scalars['Float']['output'];
  lateDays: Scalars['Float']['output'];
};

export type InvoiceContractDealershipInfo = {
  dealershipTariff: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type InvoiceContractInfo = {
  clientNumber: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dealership: InvoiceContractDealershipInfo;
  id: Scalars['String']['output'];
  installationNumber: Scalars['String']['output'];
  singleAccount: InvoiceContractSingleAccount;
};

export type InvoiceContractSingleAccount = {
  active: Scalars['Boolean']['output'];
  errorOnUnifiedInvoice?: Maybe<Scalars['String']['output']>;
  unifiedInvoice: Scalars['Boolean']['output'];
};

export type InvoiceDashboardHistoryInvoiceArrayModel = {
  history?: Maybe<Array<InvoiceHistoryDashboardModel>>;
};

export type InvoiceDashboardHistoryRewardsInvoiceArrayModel = {
  rewardHistory: Array<InvoiceHistoryRewardsDashboardModel>;
};

export type InvoiceDashboardInfoLastEconomyModel = {
  amountCents: Scalars['Float']['output'];
  month: Scalars['Float']['output'];
};

export type InvoiceDashboardInfoModel = {
  lastEconomy: InvoiceDashboardInfoLastEconomyModel;
  lastInvoiceAmountCents: Scalars['Float']['output'];
  numberOfInvoices: Scalars['Float']['output'];
  overdueInvoices: Scalars['Float']['output'];
};

export type InvoiceDashboardUnifiedModel = {
  history?: Maybe<Array<InvoiceHistoryDashboardModel>>;
  rewardHistory: Array<InvoiceHistoryRewardsDashboardModel>;
};

export type InvoiceHistoryDashboardModel = {
  amountCents: Scalars['Float']['output'];
  month: Scalars['Float']['output'];
  year: Scalars['Float']['output'];
};

export type InvoiceHistoryRewardsDashboardModel = {
  amountCents: Scalars['Float']['output'];
  month: Scalars['Float']['output'];
  year: Scalars['Float']['output'];
};

export type InvoiceListDto = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<DateRangeGraphqlSchema>;
  dueDate?: InputMaybe<DateRangeGraphqlSchema>;
  inStatus?: InputMaybe<Array<EInvoiceStatus>>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  secondCopy?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  sort?: InputMaybe<InvoiceListSort>;
};

export type InvoiceListDigitalAccountModel = {
  _id: Scalars['String']['output'];
  amountCents: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  payment: InvoicePaymentModel;
  paymentDate?: Maybe<Scalars['DateTime']['output']>;
  paymentValueCents?: Maybe<Scalars['Float']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

/** paginate metadata */
export type InvoiceMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** invoices */
export type InvoiceModel = {
  _id: Scalars['ID']['output'];
  amountCents: Scalars['Int']['output'];
  benefitAmountCents: Scalars['Int']['output'];
  benefitPercentage: Scalars['Float']['output'];
  benefitType: EInvoiceBenefitType;
  consortium: CustomerRefModel;
  contract: InvoiceContractInfo;
  createdAt: Scalars['DateTime']['output'];
  customer: CustomerRefModel;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  dueDate: Scalars['DateTime']['output'];
  energyBill: EnergyBill;
  extended: Scalars['Boolean']['output'];
  hasInterestAndFine: Scalars['Boolean']['output'];
  history: Array<History>;
  indicationRef: IndicationRefModel;
  interestAndFineValues: PaymentInterestAndFineValues;
  items: Array<ItemModel>;
  lateDays: Scalars['Float']['output'];
  originalAmountCents: Scalars['Int']['output'];
  originalDueDate: Scalars['DateTime']['output'];
  paidAmountCents?: Maybe<Scalars['Float']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  payment: Array<PaymentModel>;
  status: EInvoiceStatus;
  updatedAt: Scalars['DateTime']['output'];
};

/** invoice pagination */
export type InvoicePaginateModel = {
  data: Array<InvoiceModel>;
  meta: InvoiceMetaModel;
};

export type InvoicePaymentModel = {
  barcode?: Maybe<Scalars['String']['output']>;
  digitableLine?: Maybe<Scalars['String']['output']>;
  invoiceBase64?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['String']['output']>;
  paidValue?: Maybe<Scalars['Float']['output']>;
  pixQrcode?: Maybe<Scalars['String']['output']>;
  pixText?: Maybe<Scalars['String']['output']>;
};

export type InvoiceRequestSecondCopyInput = {
  dueDate: Scalars['String']['input'];
  invoiceId: Scalars['String']['input'];
};

export type ItemModel = {
  description: Scalars['String']['output'];
  qtd?: Maybe<Scalars['String']['output']>;
  table?: Maybe<Scalars['String']['output']>;
  valueAmountCents: Scalars['Int']['output'];
  valueCentsUnit?: Maybe<Scalars['Int']['output']>;
};

export type JwtUserGraphql = {
  customerId?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partnerId?: Maybe<Scalars['String']['output']>;
  partnerOwner?: Maybe<Scalars['Boolean']['output']>;
  phone: Scalars['String']['output'];
  type: EUserType;
};

export type JwtUserGraphqlDto = {
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type JwtUserGraphqlModel = {
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type JwtUserTokenGraphql = {
  accessControlRef: UserAccessControlGraphql;
  roles: Array<Scalars['String']['output']>;
  user: JwtUserGraphql;
};

export type LegalRepresentativeAddGraphqlDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  bank?: InputMaybe<BankGraphqlDto>;
  customerId: Scalars['String']['input'];
  document?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  files?: InputMaybe<Array<CustomerFileCreateGraphqlDto>>;
  identificationNumber: Scalars['String']['input'];
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type LegalRepresentativeModel = {
  address?: Maybe<AddressGraphqlModel>;
  bank?: Maybe<BankGraphqlModel>;
  document?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  files?: Maybe<Array<CustomerFileModel>>;
  identificationNumber?: Maybe<Scalars['String']['output']>;
  maritalStatus?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
};

export type LegalRepresentativeRemoveGraphqlDto = {
  customerId: Scalars['String']['input'];
  document: Scalars['String']['input'];
};

export type LegalRepresentativeUpdateGraphqlDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  bank?: InputMaybe<BankGraphqlDto>;
  customerId: Scalars['String']['input'];
  document?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type ListAllInvoicesFiltersGraphqlDto = {
  contractId?: InputMaybe<Scalars['String']['input']>;
};

export type LoggedUserOutput = {
  /** Generated accessToken of the user */
  accessToken: Scalars['String']['output'];
};

export type Meta = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

export type Metadata = {
  contractId: Scalars['String']['output'];
};

export type Module = {
  actions: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ModuleDto = {
  actions: Array<ActionDto>;
  internalName: Scalars['String']['output'];
  publicName: Scalars['String']['output'];
};

export type ModuleInput = {
  actions: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Mutation = {
  accessControlCreate: AccessControlModel;
  accessControlDelete: Scalars['Boolean']['output'];
  accessControlUpdate: AccessControlModel;
  activateContract: ContractModel;
  activateDistributor?: Maybe<DistributorModel>;
  addNoteToProposal: ProposalModel;
  affiliatePartnerUserChangePassword: AffiliatePartnerUserRecoverPasswordModel;
  affiliatePartnerUserRecoverPassword: AffiliatePartnerUserRecoverPasswordModel;
  analyseLegalRepresentativeData: ProposalModel;
  analyseRegistrationData: ProposalModel;
  cancelContract: ContractModel;
  cancelProposal: ProposalModel;
  consortiumCreate: ConsortiumModel;
  consortiumDelete: Scalars['Boolean']['output'];
  consortiumUpdate: ConsortiumModel;
  contractAddEnergyBillInfo: ContractModel;
  contractAddObservation: ContractModel;
  contractChangeBenefit: ContractModel;
  contractChangePowerPlan: ContractModel;
  contractChangeSingleAccount: ContractModel;
  contractCreate: ContractModel;
  contractDelete: Scalars['Boolean']['output'];
  contractRequestTermAdhesionOrAssociation?: Maybe<ContractModel>;
  contractRequestTermOfManagement?: Maybe<ContractModel>;
  contractUpdate: ContractModel;
  contractUpdateCommercialCondition: ContractModel;
  contractUpdateEnergyBillInfo: ContractModel;
  contractUpdateManuallyTermManagement?: Maybe<ContractModel>;
  contractUpdateManuallyTermManagementApproveOrRepprove?: Maybe<ContractModel>;
  createAffiliatePartnerUser: AffiliatePartnerUserModel;
  createCustomerUser: CustomerUserModel;
  createIndication: IndicationModel;
  createProposalPdf: FileModel;
  createSimulationPdf: FileModel;
  createSimulationWithCashbackPdf: FileModel;
  createUser: UserModel;
  customerAddLegalRepresentant: CustomerModel;
  customerAreaCreateDigitalAccount: CustomerModel;
  customerCreate: CustomerModel;
  customerCreateDigitalAccount: CustomerModel;
  customerCreateUnLogged: CustomerModel;
  customerPayInvoicesWithCashback: CustomerUseCashbackModel;
  customerRemoveLegalRepresentant: CustomerModel;
  customerRequestInvoiceSecondCopy?: Maybe<CustomerModel>;
  customerUpdateById: CustomerModel;
  customerUpdateLegalRepresentant: CustomerModel;
  customerUserChangePassword: CustomerUserRecoverPasswordModel;
  customerUserRecoverPassword: CustomerUserRecoverPasswordModel;
  customerWithdrawRequest: CustomerWithdrawModel;
  dashboardHistoryInvoicesCustomerUser: InvoiceDashboardHistoryInvoiceArrayModel;
  dashboardHistoryInvoicesRewardsCustomerUser: InvoiceDashboardHistoryRewardsInvoiceArrayModel;
  dashboardInvoicesInfoCustomerUser: InvoiceDashboardInfoModel;
  dashboardUnifiedCustomerUser: InvoiceDashboardUnifiedModel;
  deactivateContract: ContractModel;
  deactivateDistributor?: Maybe<DistributorModel>;
  dealershipCreate: DealershipModel;
  dealershipDelete: Scalars['Boolean']['output'];
  dealershipResolutionClasseUpdate: DealershipModel;
  dealershipUpdate: DealershipModel;
  deleteAffiliatePartnerUser: AffiliatePartnerUserModel;
  deleteCustomerUser: CustomerUserModel;
  deleteUser: UserModel;
  discountTableCreate: DiscountTableModel;
  discountTableDelete?: Maybe<DiscountTableModel>;
  discountTableUpdate: DiscountTableModel;
  forInDeactivationContract: ContractModel;
  generateNewTermOfAdhesion: ProposalModel;
  getCustomerNameByHash: Scalars['String']['output'];
  listAllInvoicesCustomerUser: PaginatedInvoiceDigitalAccountModel;
  loginAffiliatePartnerUser: AffiliatePartnerUserLoggedUserOutput;
  loginCustomerUser: CustomerUserLoggedUserOutput;
  loginUser: LoggedUserOutput;
  notificationCreate: NotificationModel;
  notifyCustomerFinishedDocumentation: ProposalModel;
  partnerCreate: PartnerModel;
  partnerDelete: Scalars['Boolean']['output'];
  partnerUpdate: PartnerModel;
  powerGeneratorCreate: PowerGeneratorModel;
  powerGeneratorDelete: Scalars['Boolean']['output'];
  powerGeneratorUpdate: PowerGeneratorModel;
  powerPlantCreate: PowerPlantModel;
  powerPlantDelete: Scalars['Boolean']['output'];
  powerPlantUpdate: PowerPlantModel;
  proposalAddEnergyBillInfo: ProposalModel;
  proposalAddLegalRepresentative: ProposalModel;
  proposalAddPreProposal: ProposalModel;
  proposalCreate: ProposalModel;
  proposalFilesAnalysis: ProposalModel;
  proposalFormalization: ProposalModel;
  proposalRemoveLegalRepresentative: ProposalModel;
  proposalUpdate: ProposalModel;
  proposalUpdateLegalRepresentative: ProposalModel;
  proposalUpdateManuallyTermManagement?: Maybe<ProposalModel>;
  proposalUpdateManuallyTermManagementApproveOrRepprove?: Maybe<ProposalModel>;
  providerInvoiceCancel: ProviderInvoiceModel;
  providerInvoiceChangeDueDate: ProviderInvoiceModel;
  regenerateApiKeyFromPartner: PartnerModel;
  requestCustomerBankData: Scalars['String']['output'];
  sellerCreate: SellerModel;
  sellerDelete: Scalars['Boolean']['output'];
  sellerUpdate: SellerModel;
  sendContractToSign: ContractModel;
  signedUrlLogin: Scalars['String']['output'];
  solveProposalCustomerPendencies: ProposalModel;
  solveProposalLegalRepresentantPendencies: ProposalModel;
  updateAffiliatePartnerUser: AffiliatePartnerUserModel;
  updateDistributorsFromAPI: Array<DistributorModel>;
  updateIndication: IndicationModel;
  updateIndicationProposalAddEnergyBillInfo: ProposalModel;
  updateIndicationProposalFiles: ProposalModel;
  updateIndicationProposalUpdateLegalRepresentative: ProposalModel;
  updatePartnerAreaPartnerIcone: PartnerModel;
  updatePartnerAreaPartnerLogo: PartnerModel;
  updatePartnerDefaultConfiguration: PartnerModel;
  updatePartnerDiscountsOffered: PartnerDiscountOfferedModel;
  updatePartnerWebhook: PartnerModel;
  updateSalesFunnelStage: ProposalModel;
  updateUser: UserModel;
  userChangePassword: RecoverPasswordModel;
  userContractChangeBenefit: ContractModel;
  userContractChangeSingleAccount: ContractModel;
  userRecoverPassword: RecoverPasswordModel;
};


export type MutationAccessControlCreateArgs = {
  params: AccessControlCreateDto;
};


export type MutationAccessControlDeleteArgs = {
  params: AccessControlDeleteDto;
};


export type MutationAccessControlUpdateArgs = {
  params: AccessControlUpdateDto;
};


export type MutationActivateContractArgs = {
  contractId: Scalars['String']['input'];
};


export type MutationActivateDistributorArgs = {
  distributorId: Scalars['String']['input'];
};


export type MutationAddNoteToProposalArgs = {
  params: AddNoteGraphqlDto;
};


export type MutationAffiliatePartnerUserChangePasswordArgs = {
  RecoverPasswordChangeInput: AffiliatePartnerUserRecoverPasswordChangeInput;
};


export type MutationAffiliatePartnerUserRecoverPasswordArgs = {
  RecoverPasswordInput: AffiliatePartnerUserRecoverPasswordInput;
};


export type MutationAnalyseLegalRepresentativeDataArgs = {
  params: AnalyseLegalRepresentativeDataDto;
};


export type MutationAnalyseRegistrationDataArgs = {
  params: AnalyseRegistrationDataDto;
};


export type MutationCancelContractArgs = {
  params: CancelContractDtoGraphql;
};


export type MutationCancelProposalArgs = {
  params: CancelProposalGraphqlDto;
};


export type MutationConsortiumCreateArgs = {
  params: ConsortiumCreateDtoGraphql;
};


export type MutationConsortiumDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationConsortiumUpdateArgs = {
  params: ConsortiumUpdateDtoGraphql;
};


export type MutationContractAddEnergyBillInfoArgs = {
  params: ContractAddEnergyBillInfoGraphqlDto;
};


export type MutationContractAddObservationArgs = {
  params: ObservationCreateDtoGraphql;
};


export type MutationContractChangeBenefitArgs = {
  params: ContractChangeBenefitDto;
};


export type MutationContractChangePowerPlanArgs = {
  params: ContractChangePowerPlantDto;
};


export type MutationContractChangeSingleAccountArgs = {
  params: ContractChangeSingleAccountDto;
};


export type MutationContractCreateArgs = {
  params: ContractCreateDtoGraphql;
};


export type MutationContractDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationContractRequestTermAdhesionOrAssociationArgs = {
  contractId: Scalars['String']['input'];
};


export type MutationContractRequestTermOfManagementArgs = {
  contractId: Scalars['String']['input'];
};


export type MutationContractUpdateArgs = {
  params: ContractUpdateDtoGraphql;
};


export type MutationContractUpdateCommercialConditionArgs = {
  params: ContractUpdateCommercialCondition;
};


export type MutationContractUpdateEnergyBillInfoArgs = {
  params: ContractUpdateEnergyBillInfo;
};


export type MutationContractUpdateManuallyTermManagementArgs = {
  params: ContractUpdateManuallyTermManagement;
};


export type MutationContractUpdateManuallyTermManagementApproveOrRepproveArgs = {
  params: ContractUpdateManuallyTermManagementApproveDto;
};


export type MutationCreateAffiliatePartnerUserArgs = {
  userCreateInput: AffiliatePartnerUserCreateInput;
};


export type MutationCreateCustomerUserArgs = {
  userCreateInput: CustomerUserCreateInput;
};


export type MutationCreateIndicationArgs = {
  params: CreateIndicationPartnerDto;
};


export type MutationCreateProposalPdfArgs = {
  params: CreateProposalPdfGraphql;
};


export type MutationCreateSimulationPdfArgs = {
  params: CreateSimulationPdfGraphql;
};


export type MutationCreateSimulationWithCashbackPdfArgs = {
  params: CreateSimulationPdfGraphql;
};


export type MutationCreateUserArgs = {
  userCreateInput: UserCreateInput;
};


export type MutationCustomerAddLegalRepresentantArgs = {
  params: LegalRepresentativeAddGraphqlDto;
};


export type MutationCustomerAreaCreateDigitalAccountArgs = {
  params: CustomerCreateDigitalAccountGraphqlDto;
};


export type MutationCustomerCreateArgs = {
  params: CustomerCreateGraphqlDto;
};


export type MutationCustomerCreateDigitalAccountArgs = {
  params: CustomerCreateDigitalAccountGraphqlDto;
};


export type MutationCustomerCreateUnLoggedArgs = {
  params: CustomerCreateGraphqlDto;
};


export type MutationCustomerPayInvoicesWithCashbackArgs = {
  CustomerCashbackInvoicePayDto: CustomerCashbackInvoicePayDto;
};


export type MutationCustomerRemoveLegalRepresentantArgs = {
  params: LegalRepresentativeRemoveGraphqlDto;
};


export type MutationCustomerRequestInvoiceSecondCopyArgs = {
  params: InvoiceRequestSecondCopyInput;
};


export type MutationCustomerUpdateByIdArgs = {
  id: Scalars['String']['input'];
  params: CustomerUpdateGraphqlDto;
};


export type MutationCustomerUpdateLegalRepresentantArgs = {
  params: LegalRepresentativeUpdateGraphqlDto;
};


export type MutationCustomerUserChangePasswordArgs = {
  RecoverPasswordChangeInput: CustomerUserRecoverPasswordChangeInput;
};


export type MutationCustomerUserRecoverPasswordArgs = {
  RecoverPasswordInput: CustomerUserRecoverPasswordInput;
};


export type MutationCustomerWithdrawRequestArgs = {
  CustomerWithdrawRequestInput: CustomerWithdrawRequestInputDto;
};


export type MutationDashboardHistoryInvoicesCustomerUserArgs = {
  filters: ListAllInvoicesFiltersGraphqlDto;
};


export type MutationDashboardHistoryInvoicesRewardsCustomerUserArgs = {
  filters: ListAllInvoicesFiltersGraphqlDto;
};


export type MutationDashboardInvoicesInfoCustomerUserArgs = {
  filters: ListAllInvoicesFiltersGraphqlDto;
};


export type MutationDashboardUnifiedCustomerUserArgs = {
  filters: ListAllInvoicesFiltersGraphqlDto;
};


export type MutationDeactivateContractArgs = {
  contractId: Scalars['String']['input'];
};


export type MutationDeactivateDistributorArgs = {
  distributorId: Scalars['String']['input'];
};


export type MutationDealershipCreateArgs = {
  params: DealershipCreateDto;
};


export type MutationDealershipDeleteArgs = {
  params: DealershipDeleteDto;
};


export type MutationDealershipResolutionClasseUpdateArgs = {
  id: Scalars['String']['input'];
  params: Array<DealershipResolutionClassDto>;
};


export type MutationDealershipUpdateArgs = {
  params: DealershipUpdateDto;
};


export type MutationDeleteAffiliatePartnerUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCustomerUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDiscountTableCreateArgs = {
  params: CreateDiscountDto;
};


export type MutationDiscountTableDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationDiscountTableUpdateArgs = {
  id: Scalars['String']['input'];
  params: UpdateDiscountDto;
};


export type MutationForInDeactivationContractArgs = {
  contractId: Scalars['String']['input'];
};


export type MutationGenerateNewTermOfAdhesionArgs = {
  proposalId: Scalars['String']['input'];
};


export type MutationListAllInvoicesCustomerUserArgs = {
  filters: ListAllInvoicesFiltersGraphqlDto;
};


export type MutationLoginAffiliatePartnerUserArgs = {
  authUserInput: AuthAffiliatePartnerUserArgs;
};


export type MutationLoginCustomerUserArgs = {
  authUserInput: AuthCustomerUserArgs;
};


export type MutationLoginUserArgs = {
  authUserInput: AuthUserArgs;
};


export type MutationNotificationCreateArgs = {
  params: NotificationCreateDto;
};


export type MutationNotifyCustomerFinishedDocumentationArgs = {
  params: NotifyCustomerFinishedDocumentationGraphqlDto;
};


export type MutationPartnerCreateArgs = {
  params: PartnerCreateDto;
};


export type MutationPartnerDeleteArgs = {
  params: PartnerDeleteDto;
};


export type MutationPartnerUpdateArgs = {
  params: PartnerUpdateDto;
};


export type MutationPowerGeneratorCreateArgs = {
  params: PowerGeneratorCreateDto;
};


export type MutationPowerGeneratorDeleteArgs = {
  params: PowerGeneratorDeleteDto;
};


export type MutationPowerGeneratorUpdateArgs = {
  params: PowerGeneratorUpdateDto;
};


export type MutationPowerPlantCreateArgs = {
  params: PowerPlantCreateDto;
};


export type MutationPowerPlantDeleteArgs = {
  params: PowerPlantDeleteDto;
};


export type MutationPowerPlantUpdateArgs = {
  params: PowerPlantUpdateDto;
};


export type MutationProposalAddEnergyBillInfoArgs = {
  params: ProposalAddEnergyBillInfoGraphqlDto;
};


export type MutationProposalAddLegalRepresentativeArgs = {
  params: ProposalAddLegalRepresentativeGraphqlDto;
};


export type MutationProposalAddPreProposalArgs = {
  params: ProposalAddPreProposalGraphqlDto;
};


export type MutationProposalCreateArgs = {
  params: ProposalCreateGraphqlDto;
};


export type MutationProposalFilesAnalysisArgs = {
  id: Scalars['String']['input'];
  params: ProposalAnalysisFilesDto;
};


export type MutationProposalFormalizationArgs = {
  proposalId: Scalars['String']['input'];
};


export type MutationProposalRemoveLegalRepresentativeArgs = {
  params: ProposalRemoveLegalRepresentativeGraphqlDto;
};


export type MutationProposalUpdateArgs = {
  params: ProposalUpdateGraphqlDto;
};


export type MutationProposalUpdateLegalRepresentativeArgs = {
  params: ProposalUpdateLegalRepresentativeGraphqlDto;
};


export type MutationProposalUpdateManuallyTermManagementArgs = {
  params: ProposalUpdateManuallyTermManagement;
};


export type MutationProposalUpdateManuallyTermManagementApproveOrRepproveArgs = {
  params: ProposalUpdateManuallyTermManagementApproveDto;
};


export type MutationProviderInvoiceCancelArgs = {
  invoiceId: Scalars['String']['input'];
};


export type MutationProviderInvoiceChangeDueDateArgs = {
  params: ContractInvoiceChangeDueDateDto;
};


export type MutationRequestCustomerBankDataArgs = {
  contractId: Scalars['String']['input'];
};


export type MutationSellerCreateArgs = {
  params: SellerCreateDto;
};


export type MutationSellerDeleteArgs = {
  params: SellerDeleteDto;
};


export type MutationSellerUpdateArgs = {
  params: SellerUpdateDto;
};


export type MutationSendContractToSignArgs = {
  params: SendContractToSignDtoGraphql;
};


export type MutationSolveProposalCustomerPendenciesArgs = {
  params: SolveProposalCustomerPendenciesGraphqlDto;
};


export type MutationSolveProposalLegalRepresentantPendenciesArgs = {
  params: SolveProposalLegalRepresentantPendenciesGraphqlDto;
};


export type MutationUpdateAffiliatePartnerUserArgs = {
  id: Scalars['String']['input'];
  userUpdateInput: AffiliatePartnerUserUpdateInput;
};


export type MutationUpdateIndicationArgs = {
  params: UpdateIndicationPartnerDto;
};


export type MutationUpdateIndicationProposalAddEnergyBillInfoArgs = {
  params: UpdateIndicationProposalEnergyBillInfoDto;
};


export type MutationUpdateIndicationProposalFilesArgs = {
  params: UpdateIndicationProposalFilesDto;
};


export type MutationUpdateIndicationProposalUpdateLegalRepresentativeArgs = {
  params: UpdateIndicationProposalUpdateLegalRepresentativeGraphqlDto;
};


export type MutationUpdatePartnerAreaPartnerIconeArgs = {
  partnerIcone: FileUploadInputTypeDto;
};


export type MutationUpdatePartnerAreaPartnerLogoArgs = {
  partnerLogo: FileUploadInputTypeDto;
};


export type MutationUpdatePartnerDefaultConfigurationArgs = {
  UpdatePartnerDefaultConfigurationDto: UpdatePartnerDefaultConfigurationDto;
};


export type MutationUpdatePartnerDiscountsOfferedArgs = {
  UpdatePartnerDiscountOfferedDto: UpdatePartnerDiscountOfferedDto;
};


export type MutationUpdatePartnerWebhookArgs = {
  updatePartnerWebhookDto: UpdatePartnerWebhookDto;
};


export type MutationUpdateSalesFunnelStageArgs = {
  params: UpdateSalesFunnelStageDto;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String']['input'];
  userUpdateInput: UserUpdateInput;
};


export type MutationUserChangePasswordArgs = {
  RecoverPasswordChangeInput: RecoverPasswordChangeInput;
};


export type MutationUserContractChangeBenefitArgs = {
  params: ContractChangeBenefitDto;
};


export type MutationUserContractChangeSingleAccountArgs = {
  params: ContractChangeSingleAccountDto;
};


export type MutationUserRecoverPasswordArgs = {
  RecoverPasswordInput: RecoverPasswordInput;
};

export type NoteModel = {
  analystId: Scalars['ID']['output'];
  analystName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  text: Scalars['String']['output'];
};

export type NotificationAttachmentModel = {
  fileInBase64: Scalars['String']['output'];
  mimetype?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type NotificationCraetePersonalizationDataDto = {
  allMonthsReference?: InputMaybe<Scalars['String']['input']>;
  barCode?: InputMaybe<Scalars['String']['input']>;
  contractNumber?: InputMaybe<Scalars['String']['input']>;
  formatedValue?: InputMaybe<Scalars['String']['input']>;
  haveUniffyAccount?: InputMaybe<Scalars['String']['input']>;
  installationNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceMonth?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  invoicesLength?: InputMaybe<Scalars['String']['input']>;
  isEligibleCashback?: InputMaybe<Scalars['String']['input']>;
  isInvoiceLate?: InputMaybe<Scalars['Boolean']['input']>;
  lastMonthReference?: InputMaybe<Scalars['String']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  linkSignIn?: InputMaybe<Scalars['String']['input']>;
  linkToProposal?: InputMaybe<Scalars['String']['input']>;
  month?: InputMaybe<Scalars['String']['input']>;
  monthReference?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameConsumeUnit?: InputMaybe<Scalars['String']['input']>;
  newDueDate?: InputMaybe<Scalars['String']['input']>;
  newInvoiceValue?: InputMaybe<Scalars['Float']['input']>;
  oldInvoiceAmount?: InputMaybe<Scalars['Float']['input']>;
  partnerLogo?: InputMaybe<PartnerLogo>;
  partnerName?: InputMaybe<Scalars['String']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  vencimentAt?: InputMaybe<Scalars['String']['input']>;
  whatsappLink?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationCreateAttachmentDto = {
  fileInBase64: Scalars['String']['input'];
  mimetype?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type NotificationCreateDto = {
  attachments?: InputMaybe<Array<NotificationCreateAttachmentDto>>;
  bcc?: InputMaybe<NotificationCreateToDto>;
  bccList?: InputMaybe<Array<NotificationCreateToDto>>;
  cc?: InputMaybe<NotificationCreateToDto>;
  ccList?: InputMaybe<Array<NotificationCreateToDto>>;
  idempotence?: InputMaybe<Scalars['String']['input']>;
  message: NotificationCreateMessageDto;
  options?: InputMaybe<NotificationCreateOptionDto>;
  reployTo?: InputMaybe<NotificationCreateToDto>;
  subject: Scalars['String']['input'];
  to: NotificationCreateToDto;
  toList?: InputMaybe<Array<NotificationCreateToDto>>;
};

export type NotificationCreateMessageDto = {
  html?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationCreateOptionDto = {
  personalization?: InputMaybe<Array<NotificationCreatePersonalizationDto>>;
  schedulerTimeStamp?: InputMaybe<Scalars['Float']['input']>;
  templateId?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationCreatePersonalizationDto = {
  data: NotificationCraetePersonalizationDataDto;
  email: Scalars['String']['input'];
};

export type NotificationCreateToDto = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type NotificationListDto = {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<NotificationListSort>;
};

export type NotificationListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type NotificationMessageModel = {
  html?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type NotificationModel = {
  _id: Scalars['ID']['output'];
  attachments?: Maybe<Array<NotificationAttachmentModel>>;
  bcc?: Maybe<Array<NotificationToModel>>;
  cc?: Maybe<Array<NotificationToModel>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  message: NotificationMessageModel;
  messageKey: Scalars['String']['output'];
  options?: Maybe<NotificationOptionModel>;
  reployTo?: Maybe<NotificationToModel>;
  status: Scalars['String']['output'];
  steps: NotificationStepModel;
  subject: Scalars['String']['output'];
  to: Array<NotificationToModel>;
  updatedAt: Scalars['DateTime']['output'];
};

export type NotificationOptionModel = {
  personalization?: Maybe<Array<NotificationPersonalizationModel>>;
  schedulerTimeStamp?: Maybe<Scalars['Float']['output']>;
  templateId?: Maybe<Scalars['String']['output']>;
};

export type NotificationPersonalizationDataModel = {
  name: Scalars['String']['output'];
};

export type NotificationPersonalizationModel = {
  data: NotificationPersonalizationDataModel;
  email: Scalars['String']['output'];
};

export type NotificationStepModel = {
  bounced?: Maybe<Scalars['DateTime']['output']>;
  canceled?: Maybe<Scalars['DateTime']['output']>;
  clicked?: Maybe<Scalars['DateTime']['output']>;
  delivered?: Maybe<Scalars['DateTime']['output']>;
  opened?: Maybe<Scalars['DateTime']['output']>;
  pending?: Maybe<Scalars['DateTime']['output']>;
  sended?: Maybe<Scalars['DateTime']['output']>;
  spam?: Maybe<Scalars['DateTime']['output']>;
  unsubscribed?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationToModel = {
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type NotifyCustomerFinishedDocumentationGraphqlDto = {
  proposalId: Scalars['String']['input'];
};

export type ObservationCreateDtoGraphql = {
  _id: Scalars['String']['input'];
  text: Scalars['String']['input'];
};

export type ObservationModel = {
  analystId: Scalars['String']['output'];
  analystName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  text: Scalars['String']['output'];
};

export type OwnerResModel = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PaginatedAccessControlModel = {
  data: Array<AccessControlModel>;
  meta: Meta;
};

export type PaginatedCustomerModel = {
  data: Array<CustomerModel>;
  meta: Meta;
};

export type PaginatedDigitalAccountWalletExtractModel = {
  data: Array<DigitalAccountWalletExtractResponseModel>;
  meta: Meta;
  status: Scalars['Float']['output'];
};

export type PaginatedInvoiceDigitalAccountModel = {
  data: Array<InvoiceListDigitalAccountModel>;
  meta: Meta;
};

export type PaginatedNotificationModel = {
  data: Array<NotificationModel>;
  meta: Meta;
};

export type PaginatedPowerGeneratorModel = {
  data: Array<PowerGeneratorModel>;
  meta: Meta;
};

export type PartnerConfigurationCreateDto = {
  allowAffiliateLink: Scalars['Boolean']['input'];
  allowManualProposal: Scalars['Boolean']['input'];
  allowViewIndicationData?: InputMaybe<Scalars['Boolean']['input']>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  icone: FileUploadInputTypeDto;
  logo: FileUploadInputTypeDto;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PartnerConfigurationSchema = {
  allowAffiliateLink: Scalars['Boolean']['output'];
  allowManualProposal: Scalars['Boolean']['output'];
  allowViewIndicationData?: Maybe<Scalars['Boolean']['output']>;
  apiKey?: Maybe<Scalars['String']['output']>;
  icone?: Maybe<PartnerFileUploadModel>;
  logo?: Maybe<PartnerFileUploadModel>;
  webhookUrl?: Maybe<Scalars['String']['output']>;
};

export type PartnerConfigurationUpdateDto = {
  allowAffiliateLink?: InputMaybe<Scalars['Boolean']['input']>;
  allowManualProposal?: InputMaybe<Scalars['Boolean']['input']>;
  allowViewIndicationData?: InputMaybe<Scalars['Boolean']['input']>;
  apiKey?: InputMaybe<Scalars['String']['input']>;
  icone?: InputMaybe<FileUploadInputTypeDto>;
  logo?: InputMaybe<FileUploadInputTypeDto>;
  webhookUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PartnerCreateDto = {
  code?: InputMaybe<Scalars['String']['input']>;
  configuration: PartnerConfigurationCreateDto;
  contactPerson?: InputMaybe<ContactPersonCreateDto>;
  discountsException: Array<PartnerDiscountExceptionCreateDto>;
  document: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  partnershipConditions: PartnershipConditionsCreateDto;
  personType: EPersonType;
  phone: Scalars['String']['input'];
  site?: InputMaybe<Scalars['String']['input']>;
};

export type PartnerDealershipConditionsListDto = {
  locationUf?: InputMaybe<EBrazilStates>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<PartnerDealershipConditionsListSort>;
};

export type PartnerDealershipConditionsListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

/** paginate metadata */
export type PartnerDealershipConditionsMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** dealership conditions */
export type PartnerDealershipConditionsModel = {
  customerPFDiscountMax?: Maybe<Scalars['Float']['output']>;
  customerPFDiscountMin?: Maybe<Scalars['Float']['output']>;
  customerPFDiscountOffered?: Maybe<Scalars['Float']['output']>;
  customerPFDiscountSuggested?: Maybe<Scalars['Float']['output']>;
  customerPJDiscountMax?: Maybe<Scalars['Float']['output']>;
  customerPJDiscountMin?: Maybe<Scalars['Float']['output']>;
  customerPJDiscountOffered?: Maybe<Scalars['Float']['output']>;
  customerPJDiscountSuggested?: Maybe<Scalars['Float']['output']>;
  dealershipId: Scalars['String']['output'];
  dealershipName: Scalars['String']['output'];
  uf: Scalars['String']['output'];
};

/** dealership conditions pagination */
export type PartnerDealershipConditionsPaginateModel = {
  data: Array<PartnerDealershipConditionsModel>;
  meta: PartnerDealershipConditionsMetaModel;
};

export type PartnerDeleteDto = {
  _id: Scalars['String']['input'];
};

export type PartnerDiscountExceptionCreateDto = {
  customerPFDiscountMax: Scalars['Float']['input'];
  customerPFDiscountMin: Scalars['Float']['input'];
  customerPFDiscountSuggested: Scalars['Float']['input'];
  customerPJDiscountMax: Scalars['Float']['input'];
  customerPJDiscountMin: Scalars['Float']['input'];
  customerPJDiscountSuggested: Scalars['Float']['input'];
  dealershipId: Scalars['String']['input'];
};

export type PartnerDiscountOfferedCreateDto = {
  customerPFDiscountOffered: Scalars['Float']['input'];
  customerPJDiscountOffered: Scalars['Float']['input'];
  dealershipId: Scalars['String']['input'];
  dealershipName: Scalars['String']['input'];
};

/** partner dealership discount offered */
export type PartnerDiscountOfferedModel = {
  customerPFDiscountOffered: Scalars['Float']['output'];
  customerPJDiscountOffered: Scalars['Float']['output'];
  dealershipId: Scalars['String']['output'];
  dealershipName: Scalars['String']['output'];
};

export type PartnerDiscountOfferedToUpdateDto = {
  customerPFDiscountOffered: Scalars['Float']['input'];
  customerPJDiscountOffered: Scalars['Float']['input'];
  dealershipId: Scalars['String']['input'];
};

export type PartnerFileUploadModel = {
  fileName: Scalars['String']['output'];
  fileUrl: Scalars['String']['output'];
  key: Scalars['String']['output'];
  storageService: EStorageService;
};

export type PartnerInfoUserModel = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PartnerListDto = {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<PartnerListSort>;
};

export type PartnerListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type PartnerLogo = {
  url?: InputMaybe<Scalars['String']['input']>;
};

/** paginate metadata */
export type PartnerMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** partners */
export type PartnerModel = {
  _id: Scalars['ID']['output'];
  code?: Maybe<Scalars['String']['output']>;
  configuration: PartnerConfigurationSchema;
  contactPerson?: Maybe<ContactPersonSchema>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt: Scalars['DateTime']['output'];
  discountsException: Array<DiscountExceptionSchema>;
  discountsOffered: Array<DiscountOfferedSchema>;
  document: Scalars['String']['output'];
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partnershipConditions?: Maybe<PartnershipConditionsSchema>;
  personType: EPersonType;
  phone: Scalars['String']['output'];
  site?: Maybe<Scalars['String']['output']>;
  status: EPartnerStatus;
  updatedAt: Scalars['DateTime']['output'];
};

/** partner pagination */
export type PartnerPaginateModel = {
  data: Array<PartnerModel>;
  meta: PartnerMetaModel;
};

export type PartnerRefInputModel = {
  document: Scalars['String']['input'];
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type PartnerRefModel = {
  document: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type PartnerUpdateDto = {
  comissionDeadline?: InputMaybe<Scalars['Float']['input']>;
  comissionPercentage?: InputMaybe<Scalars['Float']['input']>;
  configuration?: InputMaybe<PartnerConfigurationUpdateDto>;
  contactPerson?: InputMaybe<ContactPersonUpdateDto>;
  discountsException?: InputMaybe<Array<PartnerDiscountExceptionCreateDto>>;
  discountsOffered?: InputMaybe<Array<PartnerDiscountOfferedCreateDto>>;
  partnerId: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EPartnerStatus>;
};

export type PartnershipConditionsCreateDto = {
  alwaysCommissionedInvoices: Scalars['Boolean']['input'];
  baseComissionPercentage: Scalars['Float']['input'];
  commissionedInvoices?: InputMaybe<Scalars['Float']['input']>;
};

export type PartnershipConditionsSchema = {
  alwaysCommissionedInvoices: Scalars['Boolean']['output'];
  baseComissionPercentage: Scalars['Float']['output'];
  commissionedInvoices?: Maybe<Scalars['Float']['output']>;
};

export type PaymentInterestAndFineValues = {
  finesPercentage: Scalars['Float']['output'];
  finesValueCents: Scalars['Float']['output'];
  interestPercentage: Scalars['Float']['output'];
  interestValueCents: Scalars['Float']['output'];
};

export type PaymentModel = {
  active: Scalars['Boolean']['output'];
  barcode: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  digitableLine: Scalars['String']['output'];
  digitalAccountId: Scalars['String']['output'];
  dueDate: Scalars['DateTime']['output'];
  hasInterestAndFine: Scalars['Boolean']['output'];
  history: Array<History>;
  interestAndFineValues: PaymentInterestAndFineValues;
  invoiceId: Scalars['ID']['output'];
  invoiceKey?: Maybe<Scalars['String']['output']>;
  paidAt?: Maybe<Scalars['DateTime']['output']>;
  paidValue?: Maybe<Scalars['Float']['output']>;
  pixQrcode?: Maybe<Scalars['String']['output']>;
  pixText?: Maybe<Scalars['String']['output']>;
  secondCopy: Scalars['Boolean']['output'];
  splits: Array<DigitalAccountInvoiceSplitModel>;
  status: EInvoiceStatus;
  template: EInvoiceTemplate;
  unifiedInvoice: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type PowerGeneratorCreateDto = {
  active: Scalars['Boolean']['input'];
  isDesperta: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  representatives: Array<PowerGeneratorRepresentativeInput>;
};

export type PowerGeneratorDeleteDto = {
  _id: Scalars['String']['input'];
};

export type PowerGeneratorListDto = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  isDesperta?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  representatives?: InputMaybe<Array<PowerGeneratorRepresentativeInput>>;
  sort?: InputMaybe<PowerGeneratorListSort>;
};

export type PowerGeneratorListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type PowerGeneratorModel = {
  _id: Scalars['ID']['output'];
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  isDesperta: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  representatives: Array<PowerGeneratorRepresentativeModel>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PowerGeneratorRepresentativeInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type PowerGeneratorRepresentativeModel = {
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type PowerGeneratorUpdateDto = {
  _id: Scalars['String']['input'];
  active?: InputMaybe<Scalars['Boolean']['input']>;
  isDesperta?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  representatives?: InputMaybe<Array<PowerGeneratorRepresentativeInput>>;
};

export type PowerPlantCashbackInfoModel = {
  active: Scalars['Boolean']['output'];
};

export type PowerPlantCreateDto = {
  acceptsPF: Scalars['Boolean']['input'];
  acceptsPJ: Scalars['Boolean']['input'];
  accessKeyExtra?: InputMaybe<Scalars['String']['input']>;
  accessKeyLogin?: InputMaybe<Scalars['String']['input']>;
  accessKeyPassword?: InputMaybe<Scalars['String']['input']>;
  address: AddressGraphqlDto;
  cashbackInfo?: InputMaybe<CashBackInfoCreateDto>;
  clientNumber: Scalars['String']['input'];
  corporateName: Scalars['String']['input'];
  dealershipId: Scalars['String']['input'];
  discountConfiguration?: InputMaybe<PowerPlantDiscountConfigurationCreateDto>;
  document: Scalars['String']['input'];
  email: Scalars['String']['input'];
  generatorId: Scalars['String']['input'];
  installation: Scalars['String']['input'];
  modality?: InputMaybe<EPowerPlantModality>;
  name: Scalars['String']['input'];
  observation: Scalars['String']['input'];
  operationStartDate?: InputMaybe<Scalars['String']['input']>;
  productionKwh: Scalars['Float']['input'];
  productionMwac: Scalars['Float']['input'];
};

export type PowerPlantDeleteDto = {
  _id: Scalars['String']['input'];
};

export type PowerPlantDiscountConfigurationCreateDto = {
  discount: PowerPlantDiscountPersonCreateDto;
  useDealershipDiscountPercentage: Scalars['Boolean']['input'];
};

export type PowerPlantDiscountConfigurationModel = {
  discount: PowerPlantDiscountPersonModel;
  useDealershipDiscountPercentage: Scalars['Boolean']['output'];
};

export type PowerPlantDiscountPersonCreateDto = {
  pf: Scalars['Float']['input'];
  pj: Scalars['Float']['input'];
};

export type PowerPlantDiscountPersonModel = {
  pf: Scalars['Float']['output'];
  pj: Scalars['Float']['output'];
};

export type PowerPlantListDto = {
  name?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<PowerPlantListSort>;
};

export type PowerPlantListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

/** paginate metadata */
export type PowerPlantMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** powerPlant */
export type PowerPlantModel = {
  _id: Scalars['ID']['output'];
  acceptsPF: Scalars['Boolean']['output'];
  acceptsPJ: Scalars['Boolean']['output'];
  accessKeyExtra?: Maybe<Scalars['String']['output']>;
  accessKeyLogin?: Maybe<Scalars['String']['output']>;
  accessKeyPassword?: Maybe<Scalars['String']['output']>;
  active: Scalars['Boolean']['output'];
  address?: Maybe<AddressGraphqlModel>;
  cashbackInfo?: Maybe<PowerPlantCashbackInfoModel>;
  clientNumber?: Maybe<Scalars['String']['output']>;
  corporateName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dealershipId: Scalars['String']['output'];
  deletedAt: Scalars['DateTime']['output'];
  discountConfiguration: PowerPlantDiscountConfigurationModel;
  document?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  generatorId: Scalars['String']['output'];
  installation: Scalars['String']['output'];
  isDesperta: Scalars['Boolean']['output'];
  modality?: Maybe<EPowerPlantModality>;
  name: Scalars['String']['output'];
  observation: Scalars['String']['output'];
  operationStartDate?: Maybe<Scalars['DateTime']['output']>;
  productionKwh: Scalars['Float']['output'];
  productionMwac: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** power plants pagination */
export type PowerPlantPaginateModel = {
  data: Array<PowerPlantModel>;
  meta: PowerPlantMetaModel;
};

export type PowerPlantUpdateDto = {
  _id: Scalars['String']['input'];
  acceptsPF?: InputMaybe<Scalars['Boolean']['input']>;
  acceptsPJ?: InputMaybe<Scalars['Boolean']['input']>;
  accessKeyExtra?: InputMaybe<Scalars['String']['input']>;
  accessKeyLogin?: InputMaybe<Scalars['String']['input']>;
  accessKeyPassword?: InputMaybe<Scalars['String']['input']>;
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<AddressGraphqlDto>;
  cashbackInfo?: InputMaybe<CashBackInfoCreateDto>;
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  corporateName?: InputMaybe<Scalars['String']['input']>;
  discountConfiguration?: InputMaybe<PowerPlantDiscountConfigurationCreateDto>;
  document?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  installation?: InputMaybe<Scalars['String']['input']>;
  modality?: InputMaybe<EPowerPlantModality>;
  name?: InputMaybe<Scalars['String']['input']>;
  observation?: InputMaybe<Scalars['String']['input']>;
  operationStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  productionKwh?: InputMaybe<Scalars['Float']['input']>;
  productionMwac?: InputMaybe<Scalars['Float']['input']>;
};

export type PreProposalModel = {
  averageEconomyPercentage: Scalars['Float']['output'];
  averageEnergyBillValue: Scalars['Float']['output'];
  averageMonthSavingValue: Scalars['Float']['output'];
  averageYearSavingValue: Scalars['Float']['output'];
  consumerInKwh?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dealershipId: Scalars['String']['output'];
  dealershipName: Scalars['String']['output'];
  file: BusinessProposalFileModel;
  rateWithTaxesCents?: Maybe<Scalars['Float']['output']>;
  realConsumerValueCents?: Maybe<Scalars['Float']['output']>;
  savingAmountCents?: Maybe<Scalars['Float']['output']>;
  streetLightingCents?: Maybe<Scalars['Float']['output']>;
};

export type ProposalAddEnergyBillInfoGraphqlDto = {
  AmountCents?: InputMaybe<Scalars['Float']['input']>;
  address?: InputMaybe<AddressGraphqlDto>;
  avarageConsumption?: InputMaybe<Scalars['Float']['input']>;
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  consumerClassification?: InputMaybe<EConsumerClassification>;
  dealershipId?: InputMaybe<Scalars['String']['input']>;
  dealershipName?: InputMaybe<Scalars['String']['input']>;
  distributionTaxes?: InputMaybe<Scalars['Float']['input']>;
  energyBillFile?: InputMaybe<ProposalFileCreateGraphqlDto>;
  energyTaxes?: InputMaybe<Scalars['Float']['input']>;
  installationNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceDueDay?: InputMaybe<Scalars['Float']['input']>;
  proposalId: Scalars['String']['input'];
  tariffClassification?: InputMaybe<ETariffClassification>;
  tension?: InputMaybe<ECustomerTension>;
  tusdValue?: InputMaybe<Scalars['Float']['input']>;
};

export type ProposalAddLegalRepresentativeGraphqlDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  document: Scalars['String']['input'];
  email: Scalars['String']['input'];
  identificationNumber?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name: Scalars['String']['input'];
  nationality?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  proposalId: Scalars['String']['input'];
};

export type ProposalAddPreProposalGraphqlDto = {
  proposalId: Scalars['String']['input'];
};

export type ProposalAnalysisFilesDto = {
  approve: Scalars['Boolean']['input'];
  legalRepresentativeDocument?: InputMaybe<Scalars['String']['input']>;
  types: Array<EProposalFileType>;
};

export type ProposalCashbackInfoModel = {
  active: Scalars['Boolean']['output'];
  cashbackPercentagePf: Scalars['Float']['output'];
  cashbackPercentagePj: Scalars['Float']['output'];
};

export type ProposalCreateGraphqlDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  averageEnergyBillValue: Scalars['Float']['input'];
  dealershipId: Scalars['String']['input'];
  dealershipName: Scalars['String']['input'];
  document: Scalars['String']['input'];
  email: Scalars['String']['input'];
  legalRepresentative?: InputMaybe<Array<CustomerLegalRepresentativeGraphqlDto>>;
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name: Scalars['String']['input'];
  nationality?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<EProposalOrigin>;
  personType: ECustomerPersonType;
  phone: Scalars['String']['input'];
  uf: Scalars['String']['input'];
  utmInfo?: InputMaybe<UtmInfoDtoGraphql>;
};

export type ProposalCustomerPendencies = {
  averageEnergyBillValue?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['Boolean']['input']>;
  complement?: InputMaybe<Scalars['Boolean']['input']>;
  country?: InputMaybe<Scalars['Boolean']['input']>;
  dealershipId?: InputMaybe<Scalars['Boolean']['input']>;
  dealershipName?: InputMaybe<Scalars['Boolean']['input']>;
  document?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  maritalStatus?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  nationality?: InputMaybe<Scalars['Boolean']['input']>;
  neighborhood?: InputMaybe<Scalars['Boolean']['input']>;
  nickname?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['Boolean']['input']>;
  personType?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['Boolean']['input']>;
  street?: InputMaybe<Scalars['Boolean']['input']>;
  uf?: InputMaybe<Scalars['Boolean']['input']>;
  zipcode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalCustomerPendenciesFields = {
  averageEnergyBillValue: CustomerPendencyFields;
  city: CustomerPendencyFields;
  complement: CustomerPendencyFields;
  country: CustomerPendencyFields;
  dealershipId: CustomerPendencyFields;
  dealershipName: CustomerPendencyFields;
  document: CustomerPendencyFields;
  email: CustomerPendencyFields;
  legalRepresentative: CustomerPendencyFields;
  maritalStatus: CustomerPendencyFields;
  name: CustomerPendencyFields;
  nationality: CustomerPendencyFields;
  neighborhood: CustomerPendencyFields;
  nickname: CustomerPendencyFields;
  number: CustomerPendencyFields;
  personType: CustomerPendencyFields;
  phone: CustomerPendencyFields;
  state: CustomerPendencyFields;
  street: CustomerPendencyFields;
  uf: CustomerPendencyFields;
  zipcode: CustomerPendencyFields;
};

export type ProposalCustomerRefModel = {
  address?: Maybe<AddressGraphqlModel>;
  averageEnergyBillValue?: Maybe<Scalars['Float']['output']>;
  dealershipId?: Maybe<Scalars['String']['output']>;
  dealershipName?: Maybe<Scalars['String']['output']>;
  document: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  hasPendencies?: Maybe<Scalars['Boolean']['output']>;
  legalRepresentative?: Maybe<Array<ProposalLegalRepresentativeModel>>;
  maritalStatus?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  nationality?: Maybe<Scalars['String']['output']>;
  pendencies?: Maybe<ProposalCustomerPendenciesFields>;
  personType: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  uf?: Maybe<Scalars['String']['output']>;
};

export type ProposalFileCreateGraphqlDto = {
  file: FileUploadInputTypeDto;
  status: EProposalFileStatus;
  type: EProposalFileType;
};

export type ProposalFileModel = {
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  analysedBy?: Maybe<Scalars['String']['output']>;
  file?: Maybe<FileUploadModel>;
  rejectedAt?: Maybe<Scalars['DateTime']['output']>;
  sendAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<EProposalFileStatus>;
  type?: Maybe<EProposalFileType>;
};

export type ProposalLegalRepresentativeModel = {
  address?: Maybe<AddressGraphqlModel>;
  bank?: Maybe<BankGraphqlModel>;
  document: Scalars['String']['output'];
  email: Scalars['String']['output'];
  files?: Maybe<Array<ProposalFileModel>>;
  identificationNumber?: Maybe<Scalars['String']['output']>;
  maritalStatus?: Maybe<EMaritalStatus>;
  name?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  pendencies?: Maybe<ProposalLegalRepresentativePendenciesFields>;
  phone: Scalars['String']['output'];
};

export type ProposalLegalRepresentativePendenciesFields = {
  city: CustomerPendencyFields;
  complement: CustomerPendencyFields;
  country: CustomerPendencyFields;
  document: CustomerPendencyFields;
  email: CustomerPendencyFields;
  identificationNumber: CustomerPendencyFields;
  maritalStatus: CustomerPendencyFields;
  name: CustomerPendencyFields;
  nationality: CustomerPendencyFields;
  neighborhood: CustomerPendencyFields;
  number: CustomerPendencyFields;
  phone: CustomerPendencyFields;
  state: CustomerPendencyFields;
  street: CustomerPendencyFields;
  zipcode: CustomerPendencyFields;
};

export type ProposalLegalRepresentativesPendencies = {
  city?: InputMaybe<Scalars['Boolean']['input']>;
  complement?: InputMaybe<Scalars['Boolean']['input']>;
  customerPjRepresentativeDocumentWithPhotoBack?: InputMaybe<Scalars['Boolean']['input']>;
  customerPjRepresentativeDocumentWithPhotoFront?: InputMaybe<Scalars['Boolean']['input']>;
  document?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['Boolean']['input']>;
  identificationNumber?: InputMaybe<Scalars['Boolean']['input']>;
  maritalStatus?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['Boolean']['input']>;
  nationality?: InputMaybe<Scalars['Boolean']['input']>;
  neighborhood?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Scalars['Boolean']['input']>;
  street?: InputMaybe<Scalars['Boolean']['input']>;
  zipcode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProposalListDto = {
  createBy?: InputMaybe<JwtUserGraphqlDto>;
  dateRange?: InputMaybe<DateRangeGraphqlSchema>;
  hasAnalysis?: InputMaybe<Scalars['Boolean']['input']>;
  hasPendencies?: InputMaybe<Scalars['Boolean']['input']>;
  inOrigin?: InputMaybe<Array<EProposalOrigin>>;
  inPendencies?: InputMaybe<Array<EProposalFilterListPendencies>>;
  inPendenciesStatus?: InputMaybe<Array<EProposalPendenciesStatus>>;
  inStatus?: InputMaybe<Array<EProposalStatus>>;
  nameOrDocument?: InputMaybe<Scalars['String']['input']>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  partnerId?: InputMaybe<Scalars['String']['input']>;
  salesFunnelInStage?: InputMaybe<Array<EStage>>;
  sort?: InputMaybe<ProposalListSort>;
};

export type ProposalListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

/** paginate metadata */
export type ProposalMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** proposal */
export type ProposalModel = {
  _id: Scalars['ID']['output'];
  businessProposals: Array<BusinessProposalModel>;
  cashbackInfo?: Maybe<ProposalCashbackInfoModel>;
  contractId?: Maybe<Scalars['String']['output']>;
  createBy?: Maybe<JwtUserGraphqlModel>;
  createdAt: Scalars['DateTime']['output'];
  customerRef: ProposalCustomerRefModel;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  energyBillInfo?: Maybe<PropsoalEnergyBillInfo>;
  files?: Maybe<Array<ProposalFileModel>>;
  hasAnalysis: Scalars['Boolean']['output'];
  hash: Scalars['String']['output'];
  indicationRef?: Maybe<IndicationRefModel>;
  lossReason?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Array<NoteModel>>;
  origin: EProposalOrigin;
  preProposals?: Maybe<Array<PreProposalModel>>;
  rejectedInfo?: Maybe<RejectInfoModel>;
  salesFunnel?: Maybe<SalesFunnelModel>;
  status: EProposalStatus;
  termOfAdhesion?: Maybe<TermOfAdhesionModel>;
  updatedAt: Scalars['DateTime']['output'];
  utmInfo?: Maybe<ProposalUtmInfo>;
};

/** proposals pagination */
export type ProposalPaginateModel = {
  data: Array<ProposalModel>;
  meta: ProposalMetaModel;
};

export type ProposalRemoveLegalRepresentativeGraphqlDto = {
  document: Scalars['String']['input'];
  proposalId: Scalars['String']['input'];
};

export type ProposalUpdateGraphqlDto = {
  _id: Scalars['String']['input'];
  address?: InputMaybe<AddressGraphqlDto>;
  averageEnergyBillValue?: InputMaybe<Scalars['Float']['input']>;
  dealershipId?: InputMaybe<Scalars['String']['input']>;
  dealershipName?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  personType?: InputMaybe<ECustomerPersonType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  uf?: InputMaybe<Scalars['String']['input']>;
};

export type ProposalUpdateLegalRepresentativeGraphqlDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  documentNumber: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  identificationNumber?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  proposalId: Scalars['String']['input'];
};

export type ProposalUpdateManuallyTermManagement = {
  file: FilesUploadInputGraphqlDto;
  proposalId: Scalars['String']['input'];
};

export type ProposalUpdateManuallyTermManagementApproveDto = {
  approve: Scalars['Boolean']['input'];
  proposalId: Scalars['String']['input'];
  refusedReason?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['String']['input'];
};

export type ProposalUtmInfo = {
  utm_campaign?: Maybe<Scalars['String']['output']>;
  utm_content?: Maybe<Scalars['String']['output']>;
  utm_medium?: Maybe<Scalars['String']['output']>;
  utm_source?: Maybe<Scalars['String']['output']>;
  utm_term?: Maybe<Scalars['String']['output']>;
};

export type PropsoalEnergyBillInfo = {
  AmountCents?: Maybe<Scalars['Float']['output']>;
  address?: Maybe<AddressGraphqlModel>;
  avarageConsumption?: Maybe<Scalars['Float']['output']>;
  clientNumber?: Maybe<Scalars['String']['output']>;
  consumerClassification?: Maybe<EConsumerClassification>;
  dealershipId?: Maybe<Scalars['String']['output']>;
  dealershipName?: Maybe<Scalars['String']['output']>;
  distributionTaxes?: Maybe<Scalars['Float']['output']>;
  energyBillFile?: Maybe<ProposalFileModel>;
  energyTaxes?: Maybe<Scalars['Float']['output']>;
  installationNumber?: Maybe<Scalars['String']['output']>;
  invoiceDueDay?: Maybe<Scalars['Float']['output']>;
  tariffClassification?: Maybe<ETariffClassification>;
  tension?: Maybe<ECustomerTension>;
  tusdValue?: Maybe<Scalars['Float']['output']>;
};

export type ProviderInvoiceHistoryModel = {
  action: Scalars['String']['output'];
  ocurredAt: Scalars['DateTime']['output'];
  status: EProviderInvoiceStatus;
};

/** Provider Invoices */
export type ProviderInvoiceModel = {
  _id: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerDocument: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  digitalAccountInvoiceId?: Maybe<Scalars['String']['output']>;
  history: Array<ProviderInvoiceHistoryModel>;
  installationNumber: Scalars['String']['output'];
  provider: EProviderInvoice;
  providerData: Scalars['String']['output'];
  reference: ProviderInvoiceReferenceModel;
  status: EProviderInvoiceStatus;
  type: EProviderInvoiceType;
  updatedAt: Scalars['DateTime']['output'];
  valueCents: Scalars['Float']['output'];
};

export type ProviderInvoiceReferenceComposedModel = {
  energyCreditBalanceKwh: Scalars['Float']['output'];
  energyCreditProducedKwh: Scalars['Float']['output'];
  energyCreditUsedKwh: Scalars['Float']['output'];
  energyUsedKwh: Scalars['Float']['output'];
  valueDealershipCents: Scalars['Float']['output'];
  valueDiscountPowerPlantCents: Scalars['Float']['output'];
  valueTotalPowerPlantCents: Scalars['Float']['output'];
};

export type ProviderInvoiceReferenceModel = {
  month: Scalars['String']['output'];
  values: ProviderInvoiceReferenceComposedModel;
  year: Scalars['String']['output'];
};

export type Query = {
  accessControlGet?: Maybe<AccessControlModel>;
  accessControlList: PaginatedAccessControlModel;
  actionList: Array<Scalars['String']['output']>;
  affiliatePartnerGetMe: AffiliatePartnerUserModel;
  affiliatePartnerGetMyPartner: PartnerModel;
  affiliatePartnerUserGet: AffiliatePartnerUserModel;
  affiliatePartnerUserList: AffiliatePartnerUsersPaginateModel;
  consortiumGet?: Maybe<ConsortiumModel>;
  consortiumList: ConsortiumPaginateModel;
  contractGet?: Maybe<ContractModel>;
  contractList: ContractPaginateModel;
  customerCalculateNewDueDateInvoiceId: InvoiceCalculateNewDueDate;
  customerGet?: Maybe<CustomerModel>;
  customerGetMe: CustomerModel;
  customerInvoicePayWithCashbackAvaliable: CustomerUseCashbackModel;
  customerList: PaginatedCustomerModel;
  customerUserGet: CustomerUserModel;
  customerUserGetMe: CustomerUserModel;
  customerUserGetMyContracts: ContractPaginateModel;
  customerUserList: CustomerUsersPaginateModel;
  dealershipGet?: Maybe<DealershipModel>;
  dealershipList: DealershipPaginateModel;
  discountTableById?: Maybe<DiscountTableDto>;
  discountTableByList: DiscountTablePaginateDto;
  fileGet: FileModel;
  fileGetByKey: FileModel;
  fileList: FilesPaginateModel;
  getAuthUser: JwtUserTokenGraphql;
  getCommissionMetrics: CommissionMetricsDto;
  getCommissions: CommissionPaginateModel;
  getDigitalAccountWalletExtractByCustomerId?: Maybe<PaginatedDigitalAccountWalletExtractModel>;
  getDistributors: DistributorPaginateModel;
  getIndicationById: IndicationModel;
  getIndicationMetrics: IndicationMetricsDto;
  getIndicationProposalById: ProposalModel;
  getIndications: IndicationPaginateModel;
  getMyDigitalAccountBalanceCustomerUser?: Maybe<DigitalAccountBalanceModel>;
  getMyDigitalAccountWalletExtractCustomerUser?: Maybe<PaginatedDigitalAccountWalletExtractModel>;
  getWalletBalanceByCustomerId?: Maybe<AccountWalletBalanceModel>;
  invoiceList: InvoicePaginateModel;
  modules: Array<ModuleDto>;
  modulesList: Array<Scalars['String']['output']>;
  notificationGet?: Maybe<NotificationModel>;
  notificationList: PaginatedNotificationModel;
  paginateDealershipConditions: PartnerDealershipConditionsPaginateModel;
  partnerGet?: Maybe<PartnerModel>;
  partnerList: PartnerPaginateModel;
  powerGeneratorGet?: Maybe<PowerGeneratorModel>;
  powerGeneratorList: PaginatedPowerGeneratorModel;
  powerPlantGet?: Maybe<PowerPlantModel>;
  powerPlantList: PowerPlantPaginateModel;
  proposalGet?: Maybe<ProposalModel>;
  proposalList: ProposalPaginateModel;
  sellerGet?: Maybe<SellerModel>;
  sellerList: SellerPaginateModel;
  userGet: UserModel;
  userList: UsersPaginateModel;
};


export type QueryAccessControlGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryAccessControlListArgs = {
  params?: InputMaybe<AccessControlListDto>;
};


export type QueryActionListArgs = {
  module?: InputMaybe<EModule>;
};


export type QueryAffiliatePartnerUserGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryAffiliatePartnerUserListArgs = {
  params?: InputMaybe<AffiliatePartnerUserPaginateArgs>;
};


export type QueryConsortiumGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryConsortiumListArgs = {
  params?: InputMaybe<ConsortiumListDtoGraphql>;
};


export type QueryContractGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryContractListArgs = {
  params?: InputMaybe<ContractListDtoGraphql>;
};


export type QueryCustomerCalculateNewDueDateInvoiceIdArgs = {
  invoiceId: Scalars['String']['input'];
};


export type QueryCustomerGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryCustomerListArgs = {
  params?: InputMaybe<CustomerListGraphqlDto>;
};


export type QueryCustomerUserGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryCustomerUserGetMyContractsArgs = {
  filters: GetMyContractsGraphql;
};


export type QueryCustomerUserListArgs = {
  params?: InputMaybe<CustomerUserPaginateArgs>;
};


export type QueryDealershipGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryDealershipListArgs = {
  params?: InputMaybe<DealershipListDto>;
};


export type QueryDiscountTableByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryDiscountTableByListArgs = {
  params: DiscountTableListDto;
};


export type QueryFileGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryFileGetByKeyArgs = {
  key: Scalars['String']['input'];
};


export type QueryFileListArgs = {
  params?: InputMaybe<FilePaginateArgs>;
};


export type QueryGetCommissionMetricsArgs = {
  params?: InputMaybe<CommissionMetricsArgs>;
};


export type QueryGetCommissionsArgs = {
  params?: InputMaybe<CommissionPaginateArgs>;
};


export type QueryGetDigitalAccountWalletExtractByCustomerIdArgs = {
  customerId: Scalars['String']['input'];
  filters: FilterDigitalAccountWalletExtractGraphqlDto;
};


export type QueryGetDistributorsArgs = {
  params: DistributorListDto;
};


export type QueryGetIndicationByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetIndicationMetricsArgs = {
  params?: InputMaybe<IndicationPaginateArgs>;
};


export type QueryGetIndicationProposalByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetIndicationsArgs = {
  params?: InputMaybe<IndicationPaginateArgs>;
};


export type QueryGetMyDigitalAccountWalletExtractCustomerUserArgs = {
  filters: FilterDigitalAccountWalletExtractGraphqlDto;
};


export type QueryGetWalletBalanceByCustomerIdArgs = {
  customerId: Scalars['String']['input'];
};


export type QueryInvoiceListArgs = {
  params?: InputMaybe<InvoiceListDto>;
};


export type QueryNotificationGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryNotificationListArgs = {
  params?: InputMaybe<NotificationListDto>;
};


export type QueryPaginateDealershipConditionsArgs = {
  params?: InputMaybe<PartnerDealershipConditionsListDto>;
};


export type QueryPartnerGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryPartnerListArgs = {
  params?: InputMaybe<PartnerListDto>;
};


export type QueryPowerGeneratorGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryPowerGeneratorListArgs = {
  params?: InputMaybe<PowerGeneratorListDto>;
};


export type QueryPowerPlantGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryPowerPlantListArgs = {
  params?: InputMaybe<PowerPlantListDto>;
};


export type QueryProposalGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryProposalListArgs = {
  params?: InputMaybe<ProposalListDto>;
};


export type QuerySellerGetArgs = {
  id: Scalars['String']['input'];
};


export type QuerySellerListArgs = {
  params?: InputMaybe<SellerListDto>;
};


export type QueryUserGetArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserListArgs = {
  params?: InputMaybe<UserPaginateArgs>;
};

export type RecoverPasswordChangeInput = {
  newPassword: Scalars['String']['input'];
  retypeNewPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type RecoverPasswordInput = {
  email: Scalars['String']['input'];
};

/** recoverPassword */
export type RecoverPasswordModel = {
  _id: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  status: EPasswordRecoverStatus;
  token: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userRef: UserRefModel;
};

export type ReferenceOrderModel = {
  month: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
  year: Scalars['String']['output'];
};

export type RejectInfoModel = {
  reason?: Maybe<Scalars['String']['output']>;
  rejectedAt: Scalars['DateTime']['output'];
  rejectedBy: JwtUserGraphqlModel;
};

export type RewardCreateDto = {
  history: Array<RewardHistoryUpdateDto>;
  percent: Scalars['Float']['input'];
  type: ERewardType;
};

export type RewardHistoryModel = {
  description: Scalars['String']['output'];
  ocurredAt: Scalars['DateTime']['output'];
};

export type RewardHistoryUpdateDto = {
  description: Scalars['String']['input'];
  ocurredAt: Scalars['DateTime']['input'];
};

export type RewardModel = {
  history?: Maybe<Array<RewardHistoryModel>>;
  percent: Scalars['Float']['output'];
  type: ERewardType;
};

export type RewardUpdateDto = {
  history?: InputMaybe<Array<RewardHistoryUpdateDto>>;
  percent: Scalars['Float']['input'];
  type: ERewardType;
};

export type SalesFunnelBase = {
  changedBy: EChangedBy;
  date: Scalars['DateTime']['output'];
  stage: EStage;
  user?: Maybe<Scalars['String']['output']>;
};

export type SalesFunnelModel = {
  changedBy: EChangedBy;
  date: Scalars['DateTime']['output'];
  history: Array<SalesFunnelBase>;
  stage: EStage;
  user?: Maybe<Scalars['String']['output']>;
};

export type SalesmanRefInputModel = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SalesmanRefModel = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SellerCreateDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  bank?: InputMaybe<BankGraphqlDto>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  documentNumber: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SellerDeleteDto = {
  _id: Scalars['String']['input'];
};

export type SellerListDto = {
  _id: Scalars['String']['input'];
  address?: InputMaybe<AddressGraphqlDto>;
  bank?: InputMaybe<BankGraphqlDto>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  documentNumber: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  phone: Scalars['String']['input'];
  sort?: InputMaybe<SellerListSort>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SellerListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

/** paginate metadata */
export type SellerMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** sellers */
export type SellerModel = {
  _id: Scalars['ID']['output'];
  address?: Maybe<AddressGraphqlModel>;
  bank?: Maybe<BankGraphqlModel>;
  bankAccount: Scalars['String']['output'];
  bankAgency: Scalars['String']['output'];
  birthDate: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  documentNumber: Scalars['String']['output'];
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

/** sellers pagination */
export type SellerPaginateModel = {
  data: Array<SellerModel>;
  meta: SellerMetaModel;
};

export type SellerUpdateDto = {
  _id: Scalars['String']['input'];
  address?: InputMaybe<AddressGraphqlDto>;
  bank?: InputMaybe<BankGraphqlDto>;
  birthDate?: InputMaybe<Scalars['DateTime']['input']>;
  documentNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type SendContractToSignDtoGraphql = {
  contractId: Scalars['String']['input'];
};

export type SingleAccountHitoryCustomerAreaModel = {
  active: Scalars['Boolean']['output'];
  adhesionAt?: Maybe<Scalars['DateTime']['output']>;
  adhesionBy?: Maybe<EAdhesionBySingleAccount>;
  adhesionResponsibility?: Maybe<Scalars['String']['output']>;
  ocurredAt: Scalars['DateTime']['output'];
};

export type SingleAccountHitoryModel = {
  active: Scalars['Boolean']['output'];
  adhesionAt?: Maybe<Scalars['DateTime']['output']>;
  adhesionBy?: Maybe<EAdhesionBySingleAccount>;
  adhesionResponsibility?: Maybe<Scalars['String']['output']>;
  ocurredAt: Scalars['DateTime']['output'];
};

export type SolveProposalCustomerPendenciesGraphqlDto = {
  pendencies: Array<EProposalCustomerPendencies>;
  proposalId: Scalars['String']['input'];
};

export type SolveProposalLegalRepresentantPendenciesGraphqlDto = {
  document: Scalars['String']['input'];
  pendencies: Array<EProposalLegalRepresentantPendencies>;
  proposalId: Scalars['String']['input'];
};

export type SpecialDiscountCreateDto = {
  discountPercent?: InputMaybe<Scalars['Float']['input']>;
  month: Scalars['Float']['input'];
};

export type SpecialDiscountModel = {
  discountPercent: Scalars['Float']['output'];
  month: Scalars['Float']['output'];
};

export type SpecialDiscountUpdateDto = {
  discountPercent?: InputMaybe<Scalars['Float']['input']>;
  month: Scalars['Float']['input'];
};

export type TermOfAdhesionModel = {
  _id: Scalars['String']['output'];
  approveManual?: Maybe<Scalars['Boolean']['output']>;
  createdAt: Scalars['String']['output'];
  key?: Maybe<Scalars['String']['output']>;
  manualOrAutomatic?: Maybe<ETermOfManagementType>;
  name: Scalars['String']['output'];
  refusedReason?: Maybe<Scalars['String']['output']>;
  signedAt?: Maybe<Scalars['String']['output']>;
  signedDocumentUrl?: Maybe<Scalars['String']['output']>;
  signerEmail?: Maybe<Scalars['String']['output']>;
  signerUrl?: Maybe<Scalars['String']['output']>;
  signers: Array<DocumentSignerModel>;
  status: EDocumentSignStatus;
  updatedAt: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type TermOfManagementCreateGraphqlDto = {
  active: Scalars['Boolean']['input'];
  canceledAt?: InputMaybe<Scalars['String']['input']>;
  canceledBy?: InputMaybe<Scalars['String']['input']>;
  createdAt: Scalars['String']['input'];
  createdBy: Scalars['String']['input'];
  documentSignId?: InputMaybe<Scalars['String']['input']>;
  file: ContractFileCreateDto;
  origin: Scalars['String']['input'];
  replaced: Scalars['Boolean']['input'];
  signatureLink: Scalars['String']['input'];
  signedAt?: InputMaybe<Scalars['String']['input']>;
  signerEmail?: InputMaybe<Scalars['String']['input']>;
  status: ETermStatus;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

export type TrackOperationResModel = {
  geoLocation?: Maybe<GeoLocationResModel>;
  ip: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  owner: OwnerResModel;
};

export type UpdateDiscountDto = {
  /** Informar valor entre 0 e 100 % */
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<EDiscountTableType>;
};

export type UpdateIndicationPartnerDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  averageEnergyBillValueCents?: InputMaybe<Scalars['Float']['input']>;
  commissionPercentage?: InputMaybe<Scalars['Float']['input']>;
  dealerShipName?: InputMaybe<Scalars['String']['input']>;
  dealershipId?: InputMaybe<Scalars['String']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  personType?: InputMaybe<EPersonType>;
  phone?: InputMaybe<Scalars['String']['input']>;
  uf?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIndicationProposalEnergyBillInfoDto = {
  AmountCents: Scalars['Float']['input'];
  address?: InputMaybe<AddressDto>;
  avarageConsumption?: InputMaybe<Scalars['Float']['input']>;
  clientNumber?: InputMaybe<Scalars['String']['input']>;
  consumerClassification?: InputMaybe<EConsumerClassification>;
  dealershipId?: InputMaybe<Scalars['String']['input']>;
  dealershipName?: InputMaybe<Scalars['String']['input']>;
  distributionTaxes?: InputMaybe<Scalars['Float']['input']>;
  energyBillFile?: InputMaybe<IndicationProposalFileDto>;
  energyTaxes?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
  installationNumber?: InputMaybe<Scalars['String']['input']>;
  invoiceDueDay?: InputMaybe<Scalars['Float']['input']>;
  tariffClassification?: InputMaybe<ETariffClassification>;
  tension?: InputMaybe<ECustomerTension>;
  tusdValue?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateIndicationProposalFilesDto = {
  file: FilesUploadInputGraphqlDto;
  id: Scalars['String']['input'];
  legalRepresentativeDocument?: InputMaybe<Scalars['String']['input']>;
  type: EProposalFileType;
};

export type UpdateIndicationProposalUpdateLegalRepresentativeGraphqlDto = {
  id: Scalars['String']['input'];
  legalRepresentatives: Array<UpdateLegalRepresentativeIndicationDto>;
};

export type UpdateLegalRepresentativeIndicationDto = {
  address?: InputMaybe<AddressGraphqlDto>;
  document: Scalars['String']['input'];
  email: Scalars['String']['input'];
  identificationNumber?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<EMaritalStatus>;
  name: Scalars['String']['input'];
  nationality?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};

export type UpdatePartnerDefaultConfigurationDto = {
  allowAffiliateLink: Scalars['Boolean']['input'];
  allowManualProposal: Scalars['Boolean']['input'];
};

export type UpdatePartnerDiscountOfferedDto = {
  discountOffered: PartnerDiscountOfferedToUpdateDto;
};

export type UpdatePartnerWebhookDto = {
  url: Scalars['String']['input'];
};

export type UpdateSalesFunnelStageDto = {
  lossReason?: InputMaybe<ELossReason>;
  newStage: EStage;
  proposalId: Scalars['String']['input'];
};

/** User Access control */
export type UserAccessControl = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UserAccessControlGraphql = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UserCreateInput = {
  /** access control id of the user */
  accessControlId: Scalars['String']['input'];
  /** access control name of the user */
  accessControlName: Scalars['String']['input'];
  /** username of the user */
  email: Scalars['String']['input'];
  /** name of the user */
  name: Scalars['String']['input'];
  /** password of the user */
  password: Scalars['String']['input'];
  /** phone of the user */
  phone: Scalars['String']['input'];
  /** status of the user */
  status: Scalars['String']['input'];
  /** type of user */
  type: Scalars['String']['input'];
  /** username of the user */
  username: Scalars['String']['input'];
};

export type UserCustomerRefModel = {
  document: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  personType: EPersonType;
  phone?: Maybe<Scalars['String']['output']>;
};

export type UserFilterArgs = {
  nameOrUsernameOrEmail?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EUserStatus>;
};

export type UserListSort = {
  field: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

/** paginate metadata */
export type UserMetaModel = {
  currentPage: Scalars['Int']['output'];
  documentCounts: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
};

/** user */
export type UserModel = {
  _id: Scalars['ID']['output'];
  accessControlRef: UserAccessControl;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  password: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  status: EUserStatus;
  type?: Maybe<EUserType>;
  updatedAt: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};

export type UserPaginateArgs = {
  filter?: InputMaybe<UserFilterArgs>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<UserListSort>;
};

export type UserRefModel = {
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type UserUpdateInput = {
  /** access control id of the user */
  accessControlId?: InputMaybe<Scalars['String']['input']>;
  /** access control name of the user */
  accessControlName?: InputMaybe<Scalars['String']['input']>;
  /** username of the user */
  email?: InputMaybe<Scalars['String']['input']>;
  /** name of the user */
  name?: InputMaybe<Scalars['String']['input']>;
  /** phone of the user */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** status of the user */
  status?: InputMaybe<Scalars['String']['input']>;
  /** type of user */
  type?: InputMaybe<Scalars['String']['input']>;
};

/** users pagination */
export type UsersPaginateModel = {
  data: Array<UserModel>;
  meta: UserMetaModel;
};

export type UtmInfoDtoGraphql = {
  utm_campaign: Scalars['String']['input'];
  utm_content: Scalars['String']['input'];
  utm_medium: Scalars['String']['input'];
  utm_source: Scalars['String']['input'];
  utm_term: Scalars['String']['input'];
};

export type CustomerUserRecoverPasswordMutationVariables = Exact<{
  params: CustomerUserRecoverPasswordInput;
}>;


export type CustomerUserRecoverPasswordMutation = { customerUserRecoverPassword: { _id: string } };

export type CustomerAreaCreateDigitalAccountMutationVariables = Exact<{
  params: CustomerCreateDigitalAccountGraphqlDto;
}>;


export type CustomerAreaCreateDigitalAccountMutation = { customerAreaCreateDigitalAccount: { _id: string, bank?: { account: string, agency: string, code: string, type: EBankAccountType } | null, digitalAccount?: { accountOwner?: string | null, id: string, document?: string | null, name: string, legalRepresentative?: Array<{ name: string, document?: string | null }> | null } | null } };

export type CustomerPayInvoicesWithCashbackMutationVariables = Exact<{
  params: CustomerCashbackInvoicePayDto;
}>;


export type CustomerPayInvoicesWithCashbackMutation = { customerPayInvoicesWithCashback: { balanceCashbackAfterInvoicedPaidCents: number, balanceCashbackCents: number, invoiceId: string, oldInvoiceValueCents: number } };

export type CustomerRequestInvoiceSecondCopyMutationVariables = Exact<{
  params: InvoiceRequestSecondCopyInput;
}>;


export type CustomerRequestInvoiceSecondCopyMutation = { customerRequestInvoiceSecondCopy?: { status: ECustomerStatus } | null };

export type CustomerUserChangePasswordMutationVariables = Exact<{
  params: CustomerUserRecoverPasswordChangeInput;
}>;


export type CustomerUserChangePasswordMutation = { customerUserChangePassword: { _id: string } };

export type CustomerWithdrawRequestMutationVariables = Exact<{
  params: CustomerWithdrawRequestInputDto;
}>;


export type CustomerWithdrawRequestMutation = { customerWithdrawRequest: { _id: string, createdAt: any, message?: string | null, status: ECustomerWithdrawStatus, value: number, customerRef?: { name: string, document: string, email?: string | null } | null } };

export type DashboardCustomerUserMutationVariables = Exact<{
  params: ListAllInvoicesFiltersGraphqlDto;
}>;


export type DashboardCustomerUserMutation = { dashboardHistoryInvoicesRewardsCustomerUser: { rewardHistory: Array<{ amountCents: number, month: number, year: number }> }, dashboardInvoicesInfoCustomerUser: { numberOfInvoices: number, lastInvoiceAmountCents: number, overdueInvoices: number, lastEconomy: { amountCents: number, month: number } }, dashboardHistoryInvoicesCustomerUser: { history?: Array<{ amountCents: number, month: number, year: number }> | null } };

export type ListAllInvoicesCustomerUserMutationVariables = Exact<{
  filters: ListAllInvoicesFiltersGraphqlDto;
}>;


export type ListAllInvoicesCustomerUserMutation = { listAllInvoicesCustomerUser: { data: Array<{ _id: string, createdAt: any, amountCents: number, status: string, paymentDate?: any | null, dueDate?: any | null, payment: { barcode?: string | null, digitableLine?: string | null, pixText?: string | null, pixQrcode?: string | null } }> } };

export type LoginCustomerUserMutationVariables = Exact<{
  params: AuthCustomerUserArgs;
}>;


export type LoginCustomerUserMutation = { loginCustomerUser: { accessToken: string } };

export type UserContractChangeBenefitMutationVariables = Exact<{
  params: ContractChangeBenefitDto;
}>;


export type UserContractChangeBenefitMutation = { userContractChangeBenefit: { _id: string, reward?: { type: ERewardType, percent: number, history?: Array<{ description: string, ocurredAt: any }> | null } | null } };

export type UserContractChangeSingleAccountMutationVariables = Exact<{
  params: ContractChangeSingleAccountDto;
}>;


export type UserContractChangeSingleAccountMutation = { userContractChangeSingleAccount: { singleAccount?: { active: boolean, adhesionAt?: any | null, adhesionResponsibility?: string | null, history: Array<{ active: boolean }> } | null } };

export type CustomerCalculateNewDueDateInvoiceIdQueryVariables = Exact<{
  invoiceId: Scalars['String']['input'];
}>;


export type CustomerCalculateNewDueDateInvoiceIdQuery = { customerCalculateNewDueDateInvoiceId: { invoiceId: string, newDueDateCalculated: Array<{ dueDate: any, invoiceValue: number, fineAmountCents: number, interestAmountCents: number, amountCents: number, lateDays: number }> } };

export type CustomerGetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerGetMeQuery = { customerGetMe: { _id: string, email: string, name: string, phone: string, status: ECustomerStatus, createdAt: any, personType: string, document: string, legalRepresentatives?: Array<{ document?: string | null, name?: string | null }> | null, address?: { city: string, complement?: string | null, country: string, neighborhood: string, number: string, state: string, street: string, zipcode: string } | null, bank?: { account: string, agency: string, code: string, type: EBankAccountType } | null, digitalAccount?: { id: string, status: string } | null } };

export type CustomerInvoicePayWithCashbackAvaliableQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerInvoicePayWithCashbackAvaliableQuery = { customerInvoicePayWithCashbackAvaliable: { balanceCashbackAfterInvoicedPaidCents: number, balanceCashbackCents: number, invoiceId: string, oldInvoiceValueCents: number } };

export type CustomerUserGetMyContractsQueryVariables = Exact<{
  params: GetMyContractsGraphql;
}>;


export type CustomerUserGetMyContractsQuery = { customerUserGetMyContracts: { data: Array<{ _id: string, createdAt?: any | null, updatedAt?: any | null, eligibleForCashback: boolean, customerRef: { name: string }, singleAccount?: { active: boolean, adhesionAt?: any | null, adhesionResponsibility?: string | null, history: Array<{ active: boolean }> } | null, energyBillInfo?: { name?: string | null, dealershipName?: string | null, clientNumber?: string | null, address?: { city: string, complement?: string | null, neighborhood: string, number: string, state: string, zipcode: string, street: string } | null } | null, reward?: { percent: number, type: ERewardType, history?: Array<{ description: string, ocurredAt: any }> | null } | null }> } };

export type GetMyDigitalAccountWalletExtractCustomerUserQueryVariables = Exact<{
  filters: FilterDigitalAccountWalletExtractGraphqlDto;
}>;


export type GetMyDigitalAccountWalletExtractCustomerUserQuery = { getMyDigitalAccountWalletExtractCustomerUser?: { data: Array<{ balanceCents: number, createdAt: string, operationType: string, description: string, valueCents: number, status: string, _id: string }> } | null };

export type WalletInformationsQueryVariables = Exact<{ [key: string]: never; }>;


export type WalletInformationsQuery = { getMyDigitalAccountBalanceCustomerUser?: { balanceCents?: number | null } | null };


export const CustomerUserRecoverPasswordDocument = gql`
    mutation CustomerUserRecoverPassword($params: CustomerUserRecoverPasswordInput!) {
  customerUserRecoverPassword(RecoverPasswordInput: $params) {
    _id
  }
}
    `;
export type CustomerUserRecoverPasswordMutationFn = Apollo.MutationFunction<CustomerUserRecoverPasswordMutation, CustomerUserRecoverPasswordMutationVariables>;

/**
 * __useCustomerUserRecoverPasswordMutation__
 *
 * To run a mutation, you first call `useCustomerUserRecoverPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerUserRecoverPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerUserRecoverPasswordMutation, { data, loading, error }] = useCustomerUserRecoverPasswordMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCustomerUserRecoverPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CustomerUserRecoverPasswordMutation, CustomerUserRecoverPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerUserRecoverPasswordMutation, CustomerUserRecoverPasswordMutationVariables>(CustomerUserRecoverPasswordDocument, options);
      }
export type CustomerUserRecoverPasswordMutationHookResult = ReturnType<typeof useCustomerUserRecoverPasswordMutation>;
export type CustomerUserRecoverPasswordMutationResult = Apollo.MutationResult<CustomerUserRecoverPasswordMutation>;
export type CustomerUserRecoverPasswordMutationOptions = Apollo.BaseMutationOptions<CustomerUserRecoverPasswordMutation, CustomerUserRecoverPasswordMutationVariables>;
export const CustomerAreaCreateDigitalAccountDocument = gql`
    mutation CustomerAreaCreateDigitalAccount($params: CustomerCreateDigitalAccountGraphqlDTO!) {
  customerAreaCreateDigitalAccount(params: $params) {
    _id
    bank {
      account
      agency
      code
      type
    }
    digitalAccount {
      accountOwner
      id
      document
      name
      legalRepresentative {
        name
        document
      }
    }
  }
}
    `;
export type CustomerAreaCreateDigitalAccountMutationFn = Apollo.MutationFunction<CustomerAreaCreateDigitalAccountMutation, CustomerAreaCreateDigitalAccountMutationVariables>;

/**
 * __useCustomerAreaCreateDigitalAccountMutation__
 *
 * To run a mutation, you first call `useCustomerAreaCreateDigitalAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerAreaCreateDigitalAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerAreaCreateDigitalAccountMutation, { data, loading, error }] = useCustomerAreaCreateDigitalAccountMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCustomerAreaCreateDigitalAccountMutation(baseOptions?: Apollo.MutationHookOptions<CustomerAreaCreateDigitalAccountMutation, CustomerAreaCreateDigitalAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerAreaCreateDigitalAccountMutation, CustomerAreaCreateDigitalAccountMutationVariables>(CustomerAreaCreateDigitalAccountDocument, options);
      }
export type CustomerAreaCreateDigitalAccountMutationHookResult = ReturnType<typeof useCustomerAreaCreateDigitalAccountMutation>;
export type CustomerAreaCreateDigitalAccountMutationResult = Apollo.MutationResult<CustomerAreaCreateDigitalAccountMutation>;
export type CustomerAreaCreateDigitalAccountMutationOptions = Apollo.BaseMutationOptions<CustomerAreaCreateDigitalAccountMutation, CustomerAreaCreateDigitalAccountMutationVariables>;
export const CustomerPayInvoicesWithCashbackDocument = gql`
    mutation CustomerPayInvoicesWithCashback($params: CustomerCashbackInvoicePayDto!) {
  customerPayInvoicesWithCashback(CustomerCashbackInvoicePayDto: $params) {
    balanceCashbackAfterInvoicedPaidCents
    balanceCashbackCents
    invoiceId
    oldInvoiceValueCents
  }
}
    `;
export type CustomerPayInvoicesWithCashbackMutationFn = Apollo.MutationFunction<CustomerPayInvoicesWithCashbackMutation, CustomerPayInvoicesWithCashbackMutationVariables>;

/**
 * __useCustomerPayInvoicesWithCashbackMutation__
 *
 * To run a mutation, you first call `useCustomerPayInvoicesWithCashbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerPayInvoicesWithCashbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerPayInvoicesWithCashbackMutation, { data, loading, error }] = useCustomerPayInvoicesWithCashbackMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCustomerPayInvoicesWithCashbackMutation(baseOptions?: Apollo.MutationHookOptions<CustomerPayInvoicesWithCashbackMutation, CustomerPayInvoicesWithCashbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerPayInvoicesWithCashbackMutation, CustomerPayInvoicesWithCashbackMutationVariables>(CustomerPayInvoicesWithCashbackDocument, options);
      }
export type CustomerPayInvoicesWithCashbackMutationHookResult = ReturnType<typeof useCustomerPayInvoicesWithCashbackMutation>;
export type CustomerPayInvoicesWithCashbackMutationResult = Apollo.MutationResult<CustomerPayInvoicesWithCashbackMutation>;
export type CustomerPayInvoicesWithCashbackMutationOptions = Apollo.BaseMutationOptions<CustomerPayInvoicesWithCashbackMutation, CustomerPayInvoicesWithCashbackMutationVariables>;
export const CustomerRequestInvoiceSecondCopyDocument = gql`
    mutation CustomerRequestInvoiceSecondCopy($params: InvoiceRequestSecondCopyInput!) {
  customerRequestInvoiceSecondCopy(params: $params) {
    status
  }
}
    `;
export type CustomerRequestInvoiceSecondCopyMutationFn = Apollo.MutationFunction<CustomerRequestInvoiceSecondCopyMutation, CustomerRequestInvoiceSecondCopyMutationVariables>;

/**
 * __useCustomerRequestInvoiceSecondCopyMutation__
 *
 * To run a mutation, you first call `useCustomerRequestInvoiceSecondCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerRequestInvoiceSecondCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerRequestInvoiceSecondCopyMutation, { data, loading, error }] = useCustomerRequestInvoiceSecondCopyMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCustomerRequestInvoiceSecondCopyMutation(baseOptions?: Apollo.MutationHookOptions<CustomerRequestInvoiceSecondCopyMutation, CustomerRequestInvoiceSecondCopyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerRequestInvoiceSecondCopyMutation, CustomerRequestInvoiceSecondCopyMutationVariables>(CustomerRequestInvoiceSecondCopyDocument, options);
      }
export type CustomerRequestInvoiceSecondCopyMutationHookResult = ReturnType<typeof useCustomerRequestInvoiceSecondCopyMutation>;
export type CustomerRequestInvoiceSecondCopyMutationResult = Apollo.MutationResult<CustomerRequestInvoiceSecondCopyMutation>;
export type CustomerRequestInvoiceSecondCopyMutationOptions = Apollo.BaseMutationOptions<CustomerRequestInvoiceSecondCopyMutation, CustomerRequestInvoiceSecondCopyMutationVariables>;
export const CustomerUserChangePasswordDocument = gql`
    mutation CustomerUserChangePassword($params: CustomerUserRecoverPasswordChangeInput!) {
  customerUserChangePassword(RecoverPasswordChangeInput: $params) {
    _id
  }
}
    `;
export type CustomerUserChangePasswordMutationFn = Apollo.MutationFunction<CustomerUserChangePasswordMutation, CustomerUserChangePasswordMutationVariables>;

/**
 * __useCustomerUserChangePasswordMutation__
 *
 * To run a mutation, you first call `useCustomerUserChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerUserChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerUserChangePasswordMutation, { data, loading, error }] = useCustomerUserChangePasswordMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCustomerUserChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<CustomerUserChangePasswordMutation, CustomerUserChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerUserChangePasswordMutation, CustomerUserChangePasswordMutationVariables>(CustomerUserChangePasswordDocument, options);
      }
export type CustomerUserChangePasswordMutationHookResult = ReturnType<typeof useCustomerUserChangePasswordMutation>;
export type CustomerUserChangePasswordMutationResult = Apollo.MutationResult<CustomerUserChangePasswordMutation>;
export type CustomerUserChangePasswordMutationOptions = Apollo.BaseMutationOptions<CustomerUserChangePasswordMutation, CustomerUserChangePasswordMutationVariables>;
export const CustomerWithdrawRequestDocument = gql`
    mutation CustomerWithdrawRequest($params: CustomerWithdrawRequestInputDto!) {
  customerWithdrawRequest(CustomerWithdrawRequestInput: $params) {
    _id
    createdAt
    customerRef {
      name
      document
      email
    }
    message
    status
    value
  }
}
    `;
export type CustomerWithdrawRequestMutationFn = Apollo.MutationFunction<CustomerWithdrawRequestMutation, CustomerWithdrawRequestMutationVariables>;

/**
 * __useCustomerWithdrawRequestMutation__
 *
 * To run a mutation, you first call `useCustomerWithdrawRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerWithdrawRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerWithdrawRequestMutation, { data, loading, error }] = useCustomerWithdrawRequestMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCustomerWithdrawRequestMutation(baseOptions?: Apollo.MutationHookOptions<CustomerWithdrawRequestMutation, CustomerWithdrawRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomerWithdrawRequestMutation, CustomerWithdrawRequestMutationVariables>(CustomerWithdrawRequestDocument, options);
      }
export type CustomerWithdrawRequestMutationHookResult = ReturnType<typeof useCustomerWithdrawRequestMutation>;
export type CustomerWithdrawRequestMutationResult = Apollo.MutationResult<CustomerWithdrawRequestMutation>;
export type CustomerWithdrawRequestMutationOptions = Apollo.BaseMutationOptions<CustomerWithdrawRequestMutation, CustomerWithdrawRequestMutationVariables>;
export const DashboardCustomerUserDocument = gql`
    mutation DashboardCustomerUser($params: ListAllInvoicesFiltersGraphqlDto!) {
  dashboardHistoryInvoicesRewardsCustomerUser(filters: $params) {
    rewardHistory {
      amountCents
      month
      year
    }
  }
  dashboardInvoicesInfoCustomerUser(filters: $params) {
    lastEconomy {
      amountCents
      month
    }
    numberOfInvoices
    lastInvoiceAmountCents
    overdueInvoices
  }
  dashboardHistoryInvoicesCustomerUser(filters: $params) {
    history {
      amountCents
      month
      year
    }
  }
}
    `;
export type DashboardCustomerUserMutationFn = Apollo.MutationFunction<DashboardCustomerUserMutation, DashboardCustomerUserMutationVariables>;

/**
 * __useDashboardCustomerUserMutation__
 *
 * To run a mutation, you first call `useDashboardCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDashboardCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dashboardCustomerUserMutation, { data, loading, error }] = useDashboardCustomerUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useDashboardCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<DashboardCustomerUserMutation, DashboardCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DashboardCustomerUserMutation, DashboardCustomerUserMutationVariables>(DashboardCustomerUserDocument, options);
      }
export type DashboardCustomerUserMutationHookResult = ReturnType<typeof useDashboardCustomerUserMutation>;
export type DashboardCustomerUserMutationResult = Apollo.MutationResult<DashboardCustomerUserMutation>;
export type DashboardCustomerUserMutationOptions = Apollo.BaseMutationOptions<DashboardCustomerUserMutation, DashboardCustomerUserMutationVariables>;
export const ListAllInvoicesCustomerUserDocument = gql`
    mutation ListAllInvoicesCustomerUser($filters: ListAllInvoicesFiltersGraphqlDto!) {
  listAllInvoicesCustomerUser(filters: $filters) {
    data {
      _id
      createdAt
      amountCents
      status
      paymentDate
      dueDate
      payment {
        barcode
        digitableLine
        pixText
        pixQrcode
      }
    }
  }
}
    `;
export type ListAllInvoicesCustomerUserMutationFn = Apollo.MutationFunction<ListAllInvoicesCustomerUserMutation, ListAllInvoicesCustomerUserMutationVariables>;

/**
 * __useListAllInvoicesCustomerUserMutation__
 *
 * To run a mutation, you first call `useListAllInvoicesCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useListAllInvoicesCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [listAllInvoicesCustomerUserMutation, { data, loading, error }] = useListAllInvoicesCustomerUserMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListAllInvoicesCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<ListAllInvoicesCustomerUserMutation, ListAllInvoicesCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ListAllInvoicesCustomerUserMutation, ListAllInvoicesCustomerUserMutationVariables>(ListAllInvoicesCustomerUserDocument, options);
      }
export type ListAllInvoicesCustomerUserMutationHookResult = ReturnType<typeof useListAllInvoicesCustomerUserMutation>;
export type ListAllInvoicesCustomerUserMutationResult = Apollo.MutationResult<ListAllInvoicesCustomerUserMutation>;
export type ListAllInvoicesCustomerUserMutationOptions = Apollo.BaseMutationOptions<ListAllInvoicesCustomerUserMutation, ListAllInvoicesCustomerUserMutationVariables>;
export const LoginCustomerUserDocument = gql`
    mutation LoginCustomerUser($params: AuthCustomerUserArgs!) {
  loginCustomerUser(authUserInput: $params) {
    accessToken
  }
}
    `;
export type LoginCustomerUserMutationFn = Apollo.MutationFunction<LoginCustomerUserMutation, LoginCustomerUserMutationVariables>;

/**
 * __useLoginCustomerUserMutation__
 *
 * To run a mutation, you first call `useLoginCustomerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginCustomerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginCustomerUserMutation, { data, loading, error }] = useLoginCustomerUserMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useLoginCustomerUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginCustomerUserMutation, LoginCustomerUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginCustomerUserMutation, LoginCustomerUserMutationVariables>(LoginCustomerUserDocument, options);
      }
export type LoginCustomerUserMutationHookResult = ReturnType<typeof useLoginCustomerUserMutation>;
export type LoginCustomerUserMutationResult = Apollo.MutationResult<LoginCustomerUserMutation>;
export type LoginCustomerUserMutationOptions = Apollo.BaseMutationOptions<LoginCustomerUserMutation, LoginCustomerUserMutationVariables>;
export const UserContractChangeBenefitDocument = gql`
    mutation UserContractChangeBenefit($params: ContractChangeBenefitDto!) {
  userContractChangeBenefit(params: $params) {
    _id
    reward {
      type
      percent
      history {
        description
        ocurredAt
      }
    }
  }
}
    `;
export type UserContractChangeBenefitMutationFn = Apollo.MutationFunction<UserContractChangeBenefitMutation, UserContractChangeBenefitMutationVariables>;

/**
 * __useUserContractChangeBenefitMutation__
 *
 * To run a mutation, you first call `useUserContractChangeBenefitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserContractChangeBenefitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userContractChangeBenefitMutation, { data, loading, error }] = useUserContractChangeBenefitMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUserContractChangeBenefitMutation(baseOptions?: Apollo.MutationHookOptions<UserContractChangeBenefitMutation, UserContractChangeBenefitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserContractChangeBenefitMutation, UserContractChangeBenefitMutationVariables>(UserContractChangeBenefitDocument, options);
      }
export type UserContractChangeBenefitMutationHookResult = ReturnType<typeof useUserContractChangeBenefitMutation>;
export type UserContractChangeBenefitMutationResult = Apollo.MutationResult<UserContractChangeBenefitMutation>;
export type UserContractChangeBenefitMutationOptions = Apollo.BaseMutationOptions<UserContractChangeBenefitMutation, UserContractChangeBenefitMutationVariables>;
export const UserContractChangeSingleAccountDocument = gql`
    mutation UserContractChangeSingleAccount($params: ContractChangeSingleAccountDto!) {
  userContractChangeSingleAccount(params: $params) {
    singleAccount {
      active
      adhesionAt
      adhesionResponsibility
      history {
        active
      }
    }
  }
}
    `;
export type UserContractChangeSingleAccountMutationFn = Apollo.MutationFunction<UserContractChangeSingleAccountMutation, UserContractChangeSingleAccountMutationVariables>;

/**
 * __useUserContractChangeSingleAccountMutation__
 *
 * To run a mutation, you first call `useUserContractChangeSingleAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserContractChangeSingleAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userContractChangeSingleAccountMutation, { data, loading, error }] = useUserContractChangeSingleAccountMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useUserContractChangeSingleAccountMutation(baseOptions?: Apollo.MutationHookOptions<UserContractChangeSingleAccountMutation, UserContractChangeSingleAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserContractChangeSingleAccountMutation, UserContractChangeSingleAccountMutationVariables>(UserContractChangeSingleAccountDocument, options);
      }
export type UserContractChangeSingleAccountMutationHookResult = ReturnType<typeof useUserContractChangeSingleAccountMutation>;
export type UserContractChangeSingleAccountMutationResult = Apollo.MutationResult<UserContractChangeSingleAccountMutation>;
export type UserContractChangeSingleAccountMutationOptions = Apollo.BaseMutationOptions<UserContractChangeSingleAccountMutation, UserContractChangeSingleAccountMutationVariables>;
export const CustomerCalculateNewDueDateInvoiceIdDocument = gql`
    query CustomerCalculateNewDueDateInvoiceId($invoiceId: String!) {
  customerCalculateNewDueDateInvoiceId(invoiceId: $invoiceId) {
    invoiceId
    newDueDateCalculated {
      dueDate
      invoiceValue
      fineAmountCents
      interestAmountCents
      amountCents
      lateDays
    }
  }
}
    `;

/**
 * __useCustomerCalculateNewDueDateInvoiceIdQuery__
 *
 * To run a query within a React component, call `useCustomerCalculateNewDueDateInvoiceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerCalculateNewDueDateInvoiceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerCalculateNewDueDateInvoiceIdQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useCustomerCalculateNewDueDateInvoiceIdQuery(baseOptions: Apollo.QueryHookOptions<CustomerCalculateNewDueDateInvoiceIdQuery, CustomerCalculateNewDueDateInvoiceIdQueryVariables> & ({ variables: CustomerCalculateNewDueDateInvoiceIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerCalculateNewDueDateInvoiceIdQuery, CustomerCalculateNewDueDateInvoiceIdQueryVariables>(CustomerCalculateNewDueDateInvoiceIdDocument, options);
      }
export function useCustomerCalculateNewDueDateInvoiceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerCalculateNewDueDateInvoiceIdQuery, CustomerCalculateNewDueDateInvoiceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerCalculateNewDueDateInvoiceIdQuery, CustomerCalculateNewDueDateInvoiceIdQueryVariables>(CustomerCalculateNewDueDateInvoiceIdDocument, options);
        }
export function useCustomerCalculateNewDueDateInvoiceIdSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomerCalculateNewDueDateInvoiceIdQuery, CustomerCalculateNewDueDateInvoiceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerCalculateNewDueDateInvoiceIdQuery, CustomerCalculateNewDueDateInvoiceIdQueryVariables>(CustomerCalculateNewDueDateInvoiceIdDocument, options);
        }
export type CustomerCalculateNewDueDateInvoiceIdQueryHookResult = ReturnType<typeof useCustomerCalculateNewDueDateInvoiceIdQuery>;
export type CustomerCalculateNewDueDateInvoiceIdLazyQueryHookResult = ReturnType<typeof useCustomerCalculateNewDueDateInvoiceIdLazyQuery>;
export type CustomerCalculateNewDueDateInvoiceIdSuspenseQueryHookResult = ReturnType<typeof useCustomerCalculateNewDueDateInvoiceIdSuspenseQuery>;
export type CustomerCalculateNewDueDateInvoiceIdQueryResult = Apollo.QueryResult<CustomerCalculateNewDueDateInvoiceIdQuery, CustomerCalculateNewDueDateInvoiceIdQueryVariables>;
export const CustomerGetMeDocument = gql`
    query CustomerGetMe {
  customerGetMe {
    _id
    email
    name
    phone
    status
    createdAt
    personType
    document
    legalRepresentatives {
      document
      name
    }
    address {
      city
      complement
      country
      neighborhood
      number
      state
      street
      zipcode
    }
    bank {
      account
      agency
      code
      type
    }
    digitalAccount {
      id
      status
    }
  }
}
    `;

/**
 * __useCustomerGetMeQuery__
 *
 * To run a query within a React component, call `useCustomerGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerGetMeQuery(baseOptions?: Apollo.QueryHookOptions<CustomerGetMeQuery, CustomerGetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerGetMeQuery, CustomerGetMeQueryVariables>(CustomerGetMeDocument, options);
      }
export function useCustomerGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerGetMeQuery, CustomerGetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerGetMeQuery, CustomerGetMeQueryVariables>(CustomerGetMeDocument, options);
        }
export function useCustomerGetMeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomerGetMeQuery, CustomerGetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerGetMeQuery, CustomerGetMeQueryVariables>(CustomerGetMeDocument, options);
        }
export type CustomerGetMeQueryHookResult = ReturnType<typeof useCustomerGetMeQuery>;
export type CustomerGetMeLazyQueryHookResult = ReturnType<typeof useCustomerGetMeLazyQuery>;
export type CustomerGetMeSuspenseQueryHookResult = ReturnType<typeof useCustomerGetMeSuspenseQuery>;
export type CustomerGetMeQueryResult = Apollo.QueryResult<CustomerGetMeQuery, CustomerGetMeQueryVariables>;
export const CustomerInvoicePayWithCashbackAvaliableDocument = gql`
    query CustomerInvoicePayWithCashbackAvaliable {
  customerInvoicePayWithCashbackAvaliable {
    balanceCashbackAfterInvoicedPaidCents
    balanceCashbackCents
    invoiceId
    oldInvoiceValueCents
  }
}
    `;

/**
 * __useCustomerInvoicePayWithCashbackAvaliableQuery__
 *
 * To run a query within a React component, call `useCustomerInvoicePayWithCashbackAvaliableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerInvoicePayWithCashbackAvaliableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerInvoicePayWithCashbackAvaliableQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerInvoicePayWithCashbackAvaliableQuery(baseOptions?: Apollo.QueryHookOptions<CustomerInvoicePayWithCashbackAvaliableQuery, CustomerInvoicePayWithCashbackAvaliableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerInvoicePayWithCashbackAvaliableQuery, CustomerInvoicePayWithCashbackAvaliableQueryVariables>(CustomerInvoicePayWithCashbackAvaliableDocument, options);
      }
export function useCustomerInvoicePayWithCashbackAvaliableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerInvoicePayWithCashbackAvaliableQuery, CustomerInvoicePayWithCashbackAvaliableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerInvoicePayWithCashbackAvaliableQuery, CustomerInvoicePayWithCashbackAvaliableQueryVariables>(CustomerInvoicePayWithCashbackAvaliableDocument, options);
        }
export function useCustomerInvoicePayWithCashbackAvaliableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomerInvoicePayWithCashbackAvaliableQuery, CustomerInvoicePayWithCashbackAvaliableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerInvoicePayWithCashbackAvaliableQuery, CustomerInvoicePayWithCashbackAvaliableQueryVariables>(CustomerInvoicePayWithCashbackAvaliableDocument, options);
        }
export type CustomerInvoicePayWithCashbackAvaliableQueryHookResult = ReturnType<typeof useCustomerInvoicePayWithCashbackAvaliableQuery>;
export type CustomerInvoicePayWithCashbackAvaliableLazyQueryHookResult = ReturnType<typeof useCustomerInvoicePayWithCashbackAvaliableLazyQuery>;
export type CustomerInvoicePayWithCashbackAvaliableSuspenseQueryHookResult = ReturnType<typeof useCustomerInvoicePayWithCashbackAvaliableSuspenseQuery>;
export type CustomerInvoicePayWithCashbackAvaliableQueryResult = Apollo.QueryResult<CustomerInvoicePayWithCashbackAvaliableQuery, CustomerInvoicePayWithCashbackAvaliableQueryVariables>;
export const CustomerUserGetMyContractsDocument = gql`
    query CustomerUserGetMyContracts($params: GetMyContractsGraphql!) {
  customerUserGetMyContracts(filters: $params) {
    data {
      _id
      createdAt
      updatedAt
      customerRef {
        name
      }
      singleAccount {
        active
        adhesionAt
        adhesionResponsibility
        history {
          active
        }
      }
      energyBillInfo {
        name
        dealershipName
        clientNumber
        address {
          city
          complement
          neighborhood
          number
          state
          zipcode
          street
        }
      }
      eligibleForCashback
      reward {
        history {
          description
          ocurredAt
        }
        percent
        type
      }
    }
  }
}
    `;

/**
 * __useCustomerUserGetMyContractsQuery__
 *
 * To run a query within a React component, call `useCustomerUserGetMyContractsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerUserGetMyContractsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerUserGetMyContractsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCustomerUserGetMyContractsQuery(baseOptions: Apollo.QueryHookOptions<CustomerUserGetMyContractsQuery, CustomerUserGetMyContractsQueryVariables> & ({ variables: CustomerUserGetMyContractsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerUserGetMyContractsQuery, CustomerUserGetMyContractsQueryVariables>(CustomerUserGetMyContractsDocument, options);
      }
export function useCustomerUserGetMyContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerUserGetMyContractsQuery, CustomerUserGetMyContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerUserGetMyContractsQuery, CustomerUserGetMyContractsQueryVariables>(CustomerUserGetMyContractsDocument, options);
        }
export function useCustomerUserGetMyContractsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomerUserGetMyContractsQuery, CustomerUserGetMyContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerUserGetMyContractsQuery, CustomerUserGetMyContractsQueryVariables>(CustomerUserGetMyContractsDocument, options);
        }
export type CustomerUserGetMyContractsQueryHookResult = ReturnType<typeof useCustomerUserGetMyContractsQuery>;
export type CustomerUserGetMyContractsLazyQueryHookResult = ReturnType<typeof useCustomerUserGetMyContractsLazyQuery>;
export type CustomerUserGetMyContractsSuspenseQueryHookResult = ReturnType<typeof useCustomerUserGetMyContractsSuspenseQuery>;
export type CustomerUserGetMyContractsQueryResult = Apollo.QueryResult<CustomerUserGetMyContractsQuery, CustomerUserGetMyContractsQueryVariables>;
export const GetMyDigitalAccountWalletExtractCustomerUserDocument = gql`
    query GetMyDigitalAccountWalletExtractCustomerUser($filters: FilterDigitalAccountWalletExtractGraphqlDto!) {
  getMyDigitalAccountWalletExtractCustomerUser(filters: $filters) {
    data {
      balanceCents
      createdAt
      operationType
      description
      valueCents
      status
      _id
    }
  }
}
    `;

/**
 * __useGetMyDigitalAccountWalletExtractCustomerUserQuery__
 *
 * To run a query within a React component, call `useGetMyDigitalAccountWalletExtractCustomerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyDigitalAccountWalletExtractCustomerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyDigitalAccountWalletExtractCustomerUserQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetMyDigitalAccountWalletExtractCustomerUserQuery(baseOptions: Apollo.QueryHookOptions<GetMyDigitalAccountWalletExtractCustomerUserQuery, GetMyDigitalAccountWalletExtractCustomerUserQueryVariables> & ({ variables: GetMyDigitalAccountWalletExtractCustomerUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyDigitalAccountWalletExtractCustomerUserQuery, GetMyDigitalAccountWalletExtractCustomerUserQueryVariables>(GetMyDigitalAccountWalletExtractCustomerUserDocument, options);
      }
export function useGetMyDigitalAccountWalletExtractCustomerUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyDigitalAccountWalletExtractCustomerUserQuery, GetMyDigitalAccountWalletExtractCustomerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyDigitalAccountWalletExtractCustomerUserQuery, GetMyDigitalAccountWalletExtractCustomerUserQueryVariables>(GetMyDigitalAccountWalletExtractCustomerUserDocument, options);
        }
export function useGetMyDigitalAccountWalletExtractCustomerUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMyDigitalAccountWalletExtractCustomerUserQuery, GetMyDigitalAccountWalletExtractCustomerUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMyDigitalAccountWalletExtractCustomerUserQuery, GetMyDigitalAccountWalletExtractCustomerUserQueryVariables>(GetMyDigitalAccountWalletExtractCustomerUserDocument, options);
        }
export type GetMyDigitalAccountWalletExtractCustomerUserQueryHookResult = ReturnType<typeof useGetMyDigitalAccountWalletExtractCustomerUserQuery>;
export type GetMyDigitalAccountWalletExtractCustomerUserLazyQueryHookResult = ReturnType<typeof useGetMyDigitalAccountWalletExtractCustomerUserLazyQuery>;
export type GetMyDigitalAccountWalletExtractCustomerUserSuspenseQueryHookResult = ReturnType<typeof useGetMyDigitalAccountWalletExtractCustomerUserSuspenseQuery>;
export type GetMyDigitalAccountWalletExtractCustomerUserQueryResult = Apollo.QueryResult<GetMyDigitalAccountWalletExtractCustomerUserQuery, GetMyDigitalAccountWalletExtractCustomerUserQueryVariables>;
export const WalletInformationsDocument = gql`
    query WalletInformations {
  getMyDigitalAccountBalanceCustomerUser {
    balanceCents
  }
}
    `;

/**
 * __useWalletInformationsQuery__
 *
 * To run a query within a React component, call `useWalletInformationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletInformationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletInformationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWalletInformationsQuery(baseOptions?: Apollo.QueryHookOptions<WalletInformationsQuery, WalletInformationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WalletInformationsQuery, WalletInformationsQueryVariables>(WalletInformationsDocument, options);
      }
export function useWalletInformationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WalletInformationsQuery, WalletInformationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WalletInformationsQuery, WalletInformationsQueryVariables>(WalletInformationsDocument, options);
        }
export function useWalletInformationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<WalletInformationsQuery, WalletInformationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WalletInformationsQuery, WalletInformationsQueryVariables>(WalletInformationsDocument, options);
        }
export type WalletInformationsQueryHookResult = ReturnType<typeof useWalletInformationsQuery>;
export type WalletInformationsLazyQueryHookResult = ReturnType<typeof useWalletInformationsLazyQuery>;
export type WalletInformationsSuspenseQueryHookResult = ReturnType<typeof useWalletInformationsSuspenseQuery>;
export type WalletInformationsQueryResult = Apollo.QueryResult<WalletInformationsQuery, WalletInformationsQueryVariables>;